import React from 'react'
import './Header.css';
import './mobile.css';
const Header = ({User}) => {
    const handleSideBar = () => {
        const description = document.getElementsByClassName( 'description' );
        const sidebar = document.getElementsByClassName( 'admin-sidebar' );
        if ( sidebar[ 0 ].classList.contains( 'show-sidebar' ) ) {
            sidebar[0].classList.remove('show-sidebar');
            for ( let i = 0; i < description.length; i++ ){
                description[i].classList.remove('slowly-show-description')
            

            }
        } else {
            sidebar[0].classList.add('show-sidebar')
            for ( let i = 0; i < description.length; i++ ){
            description[i].classList.add('slowly-show-description')
            
        }
        }
        
    }

    return (
        <header className="admin-header">
            <div className="left">
                <img className="site-logo" src="../../images/logo-website.png" alt="CreditSync"/>
            </div>
            <div className="header-right">
               
                <p className="username">{ User && User.name?User.name.toUpperCase():null}</p>
                <i onClick={()=>handleSideBar()} className="fa fa-bars"></i>
            </div>
        </header>
    )
}

export default Header
