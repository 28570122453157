export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';
export const ADD_ITEM = 'ADD_ITEM';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const LOAD_TRANSACTIONS_ERROR = 'LOAD_TRANSACTIONS_ERROR';
export const LOAD_PAYMENT_ERROR = 'LOAD_PAYMENT_ERROR';
export const LOAD_PAYMENT = 'LOAD_PAYMENT';
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const USER_LOADED = 'USER_LOADED'
export const USER_LOADING = 'USER_LOADING';
export const SHARE_SUCCESS = 'SHARE_SUCCESS';
export const SHARE_FAIL = 'SHARE_FAIL';
export const AUTH_USER = 'AUTH_USER';
export const CHANGE_PASS_FAIL = 'CHANGE_PASS_FAIL';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const PAYMENT_INITIATION_FAIL ='PAYMENT_INITIATION_FAIL'
export const PAYMENT_INITIATION_SUCCESS = 'PAYMENT_INITIATION_SUCCESS';
export const VERIFICATION_DONE = 'VERIFICATION_DONE';
export const VERIFICATION_FAIL = 'VERIFICATION_FAIl';
export const VERIFICATION_PASS = 'VERIFICATION_PASS';
export const INITIATE_RESET_FAIL = 'INITIATE_RESET_FAIL';
export const INITIATE_RESET_SUCCESS = 'INITIATE_RESET_SUCCESS';
export const RESET_TOKEN_VERIFIED = 'RESET_TOKEN_VERIFIED';
export const RESET_PIN_DONE = 'RESET_PIN_DONE';
export const RESET_PIN_FAIL = 'RESET_PIN_FAIL';
export const RESET_PIN_PASS = 'RESET_PIN_PASS';
export const BLOCK_DONE = 'BLOCK_DONE';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const ORDER_AIRTIME = 'ORDER_AIRTIME';
export const ORDER_DATA = 'ORDER_DATA';
export const ORDER_CABLETV = 'ORDER_CABLETV';
export const ORDER_ELECTRICITY = 'ORDER_ELECTRICITY';
export const ORDER_EDUCATION = 'ORDER_EDUCATION';
export const REVERSE_TRANSACTION = 'REVERSE_TRANSACTION';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const GET_ATG_BALANCE = 'GET_ATG_BALANCE';
export const GET_VT_BALANCE = 'GET_VT_BALANCE';
export const REFRESH_TOKEN_SUCCESS= 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAIL= 'REFRESH_TOKEN_FAIL'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const BUY_COUPON = 'BUY_COUPON'
export const  LOAD_COUPON = 'LOAD_COUPON'
export const CREATE_PIN_SUCCESS = 'CREATE_PIN_SUCCESS';
export const CREATE_PIN_FAIL = 'CREATE_PIN_FAIL';