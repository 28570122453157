import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import './App.css';
import AdminDashboard from './components/Admin/Dashboard/Dashboard';
import ForgotPassword from './components/Authentication/Forgotpassword/Forgotpassword';
import Resetpassword from './components/Authentication/Resetpassword/Resetpassword';
import Login from './components/Authentication/Login/Login';
import SignUp from './components/Authentication/SignUp/SignUp';
import Home from './components/Home/Landing';
import Error from './components/404/404';
import PopUps from './components/PopUps/PopUps';
import VerifyEmail from './components/Authentication/VerifyEmail/verify';
import Faqs from './components/Faqs/faqs';
// import QuickBuy from './components/QuickBuy'
import { ProtectedRoute, ProtectAdmin, RedirectHome } from './Protect';
import CreatePin from './components/Authentication/CreateSecretPin';
import Privacy from './components/PrivacyPolicy/Privacy';

const Routing = ({ data }) => {
  const { isAuthenticated, user, token, isAdmin, products } = data;

  return (
    <Switch>
      <Route exact path='/' Products={products} component={Home} />
      {/* <Route exact path='/quickbuy' component={QuickBuy} /> */}

      <Route exact path='/frequently-asked-questions' component={Faqs} />
      <Route exact path='/privacy-policy' component={Privacy} />

      {/* <ProtectAdmin
        token={token}
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        path='/admin/dashboard'
        component={AdminDashboard}
      /> */}

      {/* <ProtectedRoute
        token={token}
        isAuthenticated={isAuthenticated}
        path='/user/dashboard'
        component={Dashboard}
      /> */}

      {/* <Route exact path='/auth/signup'  component={ SignUp } /> */}
      {/* <RedirectHome
        user={user}
        token={token}
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        path='/auth/signup'
        component={SignUp}
      /> */}
      {/* <RedirectHome
        user={user}
        token={token}
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        path='/auth/login'
        component={Login}
      /> */}

      {/* <RedirectHome
        user={user}
        exact
        path='/auth/createpin'
        component={CreatePin}
      /> */}
      <Route exact path='/payment/verification' component={PopUps} />
      <Route exact path='/auth/forgotpassword' component={ForgotPassword} />
      <Route
        exact
        path='/auth/resetpassword/:token'
        component={Resetpassword}
      />
      <Route exact path='/auth/verifyemail/:token' component={VerifyEmail} />

      <Route exact path='*' component={Error} />
    </Switch>
  );
};

export default Routing;
