import {
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_ERROR,
  LOAD_TRANSACTIONS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADING,
  USER_LOADED,
  AUTH_USER,
  SHARE_FAIL,
  SHARE_SUCCESS,
  CHANGE_PASS_FAIL,
  CHANGE_PASS_SUCCESS,
  PAYMENT_INITIATION_FAIL,
  PAYMENT_INITIATION_SUCCESS,
  VERIFICATION_DONE,
  INITIATE_RESET_FAIL,
  INITIATE_RESET_SUCCESS,
  RESET_TOKEN_VERIFIED,
  RESET_PIN_DONE,
  BLOCK_DONE,
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_PAYMENT,
  LOAD_PRODUCTS,
  LOAD_PAYMENT_ERROR,
  ORDER_AIRTIME,
  ORDER_DATA,
  ORDER_CABLETV,
  ORDER_ELECTRICITY,
  REVERSE_TRANSACTION,
  EDIT_PRODUCT,
  GET_ATG_BALANCE,
  GET_VT_BALANCE,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  NETWORK_ERROR,
  BUY_COUPON,
  LOAD_COUPON,
  ORDER_EDUCATION,
  CREATE_PIN_SUCCESS,
  CREATE_PIN_FAIL

} from '../actions/types';
const initialState = {
  token: localStorage.getItem('token'),
  user: null,
  transactions: [],
  isAuthenticated: false,
  isAdmin: false,
  user_loading: false,
  errorMessage: null,
  payments: [],
  users: [],
  refresh_token: localStorage.getItem('refresh_token'),
  products: [],
  allTransactions: [],
  atgBalance: null,
  vtBalance: null


}

export default (state = initialState, action) => {

  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        user: action.payload,
        isAdmin: action.payload.isAdmin,
        user_loading: false,
        transactions: action.payload.transactions
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.filter(transaction => transaction.transaction_id !== action.payload),
        user_loading: false,
      };
    case LOAD_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payload,
        user_loading: false,
      };
    case NETWORK_ERROR:
      return {
        ...state,
        user_loading: false,
      }
    case LOAD_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }

    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        user_loading: false,
      };

    case GET_ATG_BALANCE:
      return {
        ...state,
        atgBalance: action.payload
      };
    case GET_VT_BALANCE:
      return {
        ...state,
        vtBalance: action.payload
      };
    case ORDER_DATA:
    case ORDER_AIRTIME:
    case ORDER_CABLETV:
    case ORDER_ELECTRICITY:
    case REVERSE_TRANSACTION:
    case EDIT_PRODUCT:
    case BUY_COUPON:
    case LOAD_COUPON:
    case ORDER_EDUCATION:
      return {
        ...state,
        errorMessage: action.payload,
        user_loading: false,
      };
    case LOAD_PAYMENT:
      return {
        ...state,
        payments: action.payload,
        user_loading: false,
      };
    case GET_ALL_USERS_ERROR:
    case LOAD_TRANSACTIONS_ERROR:
    case DELETE_TRANSACTION_ERROR:
      return {
        ...state,
        isAdmin: false,
        user_loading: false,
      };

    case SHARE_FAIL:
    case CHANGE_PASS_FAIL:
    case PAYMENT_INITIATION_FAIL:
    case INITIATE_RESET_FAIL:
    case CREATE_PIN_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
        user_loading: false,
      };
    case SHARE_SUCCESS:

      state.user.wallet_balance = action.payload.amount
      return {
        ...state,
        user_loading: false,
        errorMessage: action.payload

      };

    case VERIFICATION_DONE:
    case RESET_TOKEN_VERIFIED:
    case RESET_PIN_DONE:
    case BLOCK_DONE:
      return {
        ...state,
        errorMessage: action.payload,
        user_loading: false,
      };
    case CHANGE_PASS_SUCCESS:
    case PAYMENT_INITIATION_SUCCESS:
    case INITIATE_RESET_SUCCESS:
    case REGISTER_SUCCESS:
    case CREATE_PIN_SUCCESS:
      return {
        ...state,
        errorMessage: action.payload,
        user_loading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        user_loading: action.payload,
        errorMessage: null
      };



    case LOGIN_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:

      localStorage.setItem('token', action.payload.JWTtoken)
      localStorage.setItem('refresh_token', action.payload.refresh_token)
      return {
        ...state,

        errorMessage: null,
        user_loading: false,
        token: action.payload.JWTtoken,
        refresh_token: action.payload.refresh_token,
        isAuthenticated: true,

      };
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
    case REFRESH_TOKEN_FAIL:
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      return {
        ...state,
        user_loading: false,
        errorMessage: action.payload,
        token: null,
        refresh_token: null,
        user: null,
        isAuthenticated: false,
      };
    case AUTH_USER:

      return {
        ...state,
        token: action.payload.token,
        refresh_token: action.payload.rToken,
        isAuthenticated: true,
        user_loading: false

        // isAdmin:true

      };
    case AUTH_ERROR:
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      return {
        ...state,
        token: null,
        refresh_token: null,
        isAuthenticated: false,
        errorMessage: action.payload,
        user_loading: false,
      };
    default:
      return state;

  };
};

