import React from 'react'
import './SideBar.css'
import './mobile.css'
import store from '../../../store'
import {HandleLogout} from '../../../actions/Action'
import {useHistory} from 'react-router-dom'
const SideBar = ( { setPage, isAdmin } ) => {
    const history = useHistory();

    const hideDescription = ( e ) => {
        const description = document.getElementsByClassName( 'description' );
        const mainpage = document.getElementsByClassName( 'mainpage' );
 
        const chev_right = e.currentTarget.children[ 1 ];
        const chev_left = e.currentTarget.children[ 0 ]

      
    
        if ( chev_left.classList.contains( 'show-chev' ) ) {
            chev_left.classList.remove( 'show-chev' );
            chev_right.style.display = "block"
            e.currentTarget.parentElement.classList.remove( 'maximize-sidebar' )
            mainpage[ 0 ].classList.remove( 'maximize-mainpage' )

            for ( let i = 0; i < description.length; i++ ) {
                description[ i ].classList.remove( 'slowly-show-description' )
            

            }

        } else {
            chev_left.classList.add( 'show-chev' );
            chev_right.style.display = "none"
            e.currentTarget.parentElement.classList.add( 'maximize-sidebar' );
            mainpage[ 0 ].classList.add( 'maximize-mainpage' );
            
            //Loop through each data
            for ( let i = 0; i < description.length; i++ ) {
                description[ i ].classList.add( 'slowly-show-description' );
            
            }
        }

        
       
    }

    const updatePage = ( { p, e } ) => {
        setPage( p );
        const sidebar = document.getElementsByClassName( 'sidebar' )
        const description = document.getElementsByClassName( 'description' );
        const left = document.getElementsByClassName( 'fa-chevron-circle-left' )
        const right = document.getElementsByClassName( 'fa-chevron-circle-right' );
        const mainpage = document.getElementsByClassName( 'mainpage' );
        let options = document.getElementsByClassName( 'desktop-options' );
        if ( sidebar[ 0 ].classList.contains( 'show-sidebar' ) ) {
            sidebar[ 0 ].classList.remove( 'show-sidebar' )
            // for ( let i = 0; i < description.length; i++ ){
            //     description[i].classList.remove('slowly-show-description')
            

            // }
        }

        if ( sidebar[ 0 ].classList.contains( 'maximize-sidebar' ) ) {
            for ( let i = 0; i < description.length; i++ ) {
                description[ i ].classList.remove( 'slowly-show-description' )
            

            }
            left[ 0 ].classList.remove( 'show-chev' );
            right[ 0 ].style.display = "block"
            sidebar[ 0 ].classList.remove( 'maximize-sidebar' );
            mainpage[ 0 ].classList.remove( 'maximize-mainpage' );
            
        }

        if ( options[ 0 ].classList.contains( 'show-options' ) ) {
            showOptions()
        }

        let optionsChildren = options[ 0 ].children;
        for ( let i = 0; i < optionsChildren.length; i++ ) {
            optionsChildren[ i ].classList.remove( 'side-bar-active' );
            
        }

        //For Direct children(this is only valid if the current target is options)
        const childrenArr = e.currentTarget.parentElement.parentElement.parentElement.children;
        
        for ( let i = 0; i < childrenArr.length; i++ ) {
            childrenArr[ i ].classList.remove( 'side-bar-active' );
        }
        //For Direct Children
        const childrenArray = e.currentTarget.parentElement.children;
        
        for ( let i = 0; i < childrenArray.length; i++ ) {
            childrenArray[ i ].classList.remove( 'side-bar-active' );
        }

        
        ////For Element in the Services Section
        const services = document.getElementsByClassName( 'services' )[ 0 ].children;
        for ( let i = 0; i < services.length; i++ ) {
            services[ i ].classList.remove( 'side-bar-active' );
        }
        ////For Element in the footer Section
        const footer = document.getElementsByClassName( 'sidebar-footer' )[ 0 ].children;
        for ( let i = 0; i < footer.length; i++ ) {
            footer[ i ].classList.remove( 'side-bar-active' );
        }
        
        ///Check the Grand Parent Section
        const grandParent = e.currentTarget.parentElement.parentElement.children;

        for ( let i = 0; i < grandParent.length; i++ ) {
            grandParent[ i ].classList.remove( 'side-bar-active' );
        }

        e.currentTarget.classList.add( 'side-bar-active' );
    };


    const logOut = () => {
        store.dispatch( HandleLogout() );
    };

    const showOptions = () => {
        let options = document.getElementsByClassName( 'desktop-options' );
        if ( options[ 0 ].classList.contains( 'show-options' ) ) {
            options[ 0 ].classList.remove( 'show-options' )
        } else {
            options[ 0 ].classList.add( 'show-options' )
        };
    };


    return (
        <section className="sidebar">
            <div className="direction hide-on-mobile" onClick={ ( e ) => hideDescription( e ) }>
                <i className="fa fa-chevron-circle-left"></i>
                <i className="fa fa-chevron-circle-right"></i>
            
            </div>
            
            <div className="overview side-bar-active" onClick={ e => updatePage( { e, p: 1 } ) }>
                <i className="fa fa-home"></i>
                <p className="description">Overview</p>
            </div>


            <div className="wallet" onClick={ e => updatePage( { e, p: 2 } ) }>
                <i className="fa fa-wallet"></i>
                <p className="description">Wallet</p>
            </div>
            <div className="transactions-div" onClick={ e => updatePage( { e, p: 3 } ) }>
                <i className="fa fa-exchange-alt"></i>
                <p className="description">Transactions</p>
            </div>

            <div className="services">
                
                <div className="airtime" onClick={ e => updatePage( { e, p: 4 } ) }>
                    <i className="fa fa-phone"></i>
                    <p className="description">Airtime</p>
                </div>
                
                <div className="data" onClick={ e => updatePage( { e, p: 5 } ) }>
                    <i className="fa fa-signal"></i>
                    <p className="description">Data</p>
                </div>
                <div className="cable-tv" onClick={ e => updatePage( { e, p: 6 } ) }>
                    <i className="fa fa-tv"></i>
                    <p className="description">CableTV</p>
                </div>
                <div className="electricity" onClick={ e => updatePage( { e, p: 7 } ) }>
                    <i className="fa fa-lightbulb"></i>
                    <p className="description">Electricity</p>
                </div>
                <div className="view-more desktop-view-only" onClick={ () => showOptions() }>
                    <i className="fa fa-arrow-right"></i>
                    <p className="description">More</p>
                </div>
                <div className="desktop-options">
                    <div className="coupon-code desktop-view-only" onClick={ e => updatePage( { e, p: 10 } ) }>
                        <i className="fa fa-gift"></i>
                        <p className="description">Buy Coupon</p>
                    </div>
                    <div className="education desktop-view-only" onClick={ e => updatePage( { e, p: 11 } ) }>
                        <i className="fa fa-book-open"></i>
                        <p className="description">Education</p>
                    </div>
                </div>
                
                <div className="coupon-code mobile-view-only" onClick={ e => updatePage( { e, p: 10 } ) }>
                    <i className="fa fa-gift"></i>
                    <p className="description">Buy Coupon</p>
                </div>
                <div className="education mobile-view-only" onClick={ e => updatePage( { e, p: 11 } ) }>
                <i className="fa fa-book-open"></i>
                <p className="description">Education</p>
                </div>
            </div>

            <div className="sidebar-footer">
                { isAdmin ? <div className="settings" onClick={ e => history.push( '/admin/dashboard' ) }>
                    <i className="fa fa-user-shield"></i>
                    <p className="description">Admin</p>
                </div> : null }
                <div className="settings" onClick={ e => updatePage( { e, p: 8 } ) }>
                    <i className="fa fa-cog"></i>
                    <p className="description">Settings</p>
                </div>
                <div className="logout" onClick={ () => logOut() }>
                    <i className="fa fa-sign-out-alt"></i>
                    <p className="description">Logout</p>
                </div>
            </div>
        </section>
    );
};

export default SideBar
