import React from 'react';
import './404.css';
import './mobile.css';
import Footer from '../Footer/Footer'

export const Error =()=>{
    return(
        <section className="404_error">
           <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>404</h1>
                        <h2>Page not found</h2>
                    </div>
                    <a href="/">Homepage</a>
                </div>
            </div>
            
        </section>
    )
}


export default Error