import React, {useState,useEffect} from 'react'
import './Payments.css'
import './mobile.css'
import Utils from '../../Utils/Functions';
import store from '../../../store'
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom'
import {initiatePayment,userLoad} from '../../../actions/Action'
import { PAYMENT_INITIATION_FAIL, PAYMENT_INITIATION_SUCCESS } from '../../../actions/types';
const Payments = ({errorMessage,setPageToShow}) => {

    const [ amount, setAmount ] = useState( '' );
    const [ error, setError ] = useState();
const history = useHistory()
    useEffect( () => {
        if ( errorMessage && errorMessage.type === PAYMENT_INITIATION_FAIL ) {
            setError( errorMessage.msg );
            Utils.showPopUp()
        } else if ( errorMessage && errorMessage.type === PAYMENT_INITIATION_SUCCESS) {
            setError('');
            setAmount('')
            // Utils.closePopUp();
            window.location.replace( errorMessage.link );
        }
    },[errorMessage])

    const pay = () => {
        const amountAlpha = amount.match( /[a-zA-Z]/g );
        const amountSpace = amount.match( /\s/g );
        const Amountnon_digit = amount.match( /\D/g );
        if ( !amount || amountAlpha || amountSpace || Amountnon_digit ) {
            return setError( 'Invalid Amount' );
        }

        setError( '' );
        const data ={
            amount
        }
        // setPageToShow
        Utils.closePopUp();
        store.dispatch(initiatePayment(data))
    }
    return (
        <section className="payments slowly-show-popups">
            <div className="mini-page">
                <h4>{ error}</h4>
                <div className="payment-system">
                    <div>
                    <i className="fa fa-wallet"></i>

                        <p>Fund Wallet</p>
                    </div>
                </div>
                <div className="form">
                    <form>
                        <div>
                            <label>Amount</label>
                            <input onChange={ (e)=>setAmount(e.target.value)}/>
                        </div>
                    </form>
                    <h5>For payment above ₦9,999 a stamp duty charge of ₦50 will be deducted. </h5>
                    <div className="buttons">
                        <p onClick={()=>pay()}>PAY</p>
                    <p onClick={()=>Utils.closePopUp()}>Close</p>
                    </div>
                </div>
            </div>
           
        </section>
    )
}

const mapStateToProps = ( { data:{errorMessage} } ) => ( {
    errorMessage
})

export default connect(mapStateToProps,null)(Payments)
