import React from 'react'
import './Overview.css';
import TotalUsers from './TotalNumberOfUsers/TotalNumberOfUsers';
import TotalNumberOfTransactions from './TotalNumberOfTransactions/TotalNumberOfTransactions';
import TotalTransactions from './TotalTransactions/TotalTransactions';
import LastLoggedUsers from './LastLoggedUsers/LastLoggedUsers';
import Wallet from './Walletbalance/Walletbalance';
import VTbalance from './Vtwallet/balance';
const Overview = ({setPageToShow,setUserDetails,Users,Transactions,Payments,Balance}) => {
    return (
        <section className="admin-overview">
            <h2>Overview</h2>
            <div className="grid-wrapper">
                <TotalUsers Users={Users} />
                <TotalNumberOfTransactions Payments={Payments} />
                <TotalTransactions Payments={Payments} Transactions={Transactions} />
                <Wallet Balance={Balance.atgBalance}/>
                <VTbalance Balance={Balance.vtBalance}/>
                
            </div>

            <LastLoggedUsers Users={Users} setUserDetails={setUserDetails} setPageToShow={ setPageToShow}/>
        </section>
    )
}

export default Overview
