import Axios from 'axios';
import store from '../store';
import Utils from '../components/Utils/Functions';
import {
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_ERROR,
  LOAD_TRANSACTIONS,
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  AUTH_USER,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADING,
  SHARE_FAIL,
  SHARE_SUCCESS,
  CHANGE_PASS_FAIL,
  CHANGE_PASS_SUCCESS,
  PAYMENT_INITIATION_FAIL,
  PAYMENT_INITIATION_SUCCESS,
  VERIFICATION_DONE,
  INITIATE_RESET_FAIL,
  INITIATE_RESET_SUCCESS,
  RESET_TOKEN_VERIFIED,
  RESET_PIN_DONE,
  BLOCK_DONE,
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_PAYMENT,
  LOAD_PAYMENT_ERROR,
  LOAD_PRODUCTS,
  ORDER_AIRTIME,
  ORDER_DATA,
  ORDER_CABLETV,
  ORDER_ELECTRICITY,
  REVERSE_TRANSACTION,
  EDIT_PRODUCT,
  GET_ATG_BALANCE,
  GET_VT_BALANCE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  NETWORK_ERROR,
  BUY_COUPON,
  LOAD_COUPON,
  ORDER_EDUCATION,
  VERIFICATION_PASS,
  VERIFICATION_FAIL,
  RESET_PIN_PASS,
  RESET_PIN_FAIL,
  CREATE_PIN_FAIL,
  CREATE_PIN_SUCCESS,
} from './types';
import settings from './settings';
const config = {
  headers: {
    'Content-Type': 'application/json',
    // "Request-Id":Utils.generateId()
  },
};

const baseURL = 'https://api-v2.creditsync.com.ng/api/v2/';
const tokenConfig = (getState) => {
  //Get Token
  const token = localStorage.getItem('token');
  config.headers['Request-Id'] = Utils.generateId();

  if (token) {
    config.headers['x-auth-token'] = token;
  }

  return config;
};

// console.log(config)

//Buy Coupon
export const buyCoupon = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/coupon/buy',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: 'purchase_coupon_success',
      msg: response.message,
    };

    dispatch({
      type: BUY_COUPON,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_coupon_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: BUY_COUPON,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Refresh Token
export const refreshToken = () => (dispatch) => {
  return;
  //    setInterval( () => {
  //Get Token
  const Rtoken = localStorage.getItem('refresh_token');
  const token = localStorage.getItem('token');

  let body = JSON.stringify({ name: 'wewerewr' });
  config.headers['x-refresh-token'] = Rtoken;
  config.headers['x-auth-token'] = token;
  // console.log(config)
  if (token && Rtoken) {
    Axios.post(baseURL + '/api/auth/renew', body, config)
      .then((res) => {
        res = res.data;

        dispatch({
          type: REFRESH_TOKEN_SUCCESS,
          payload: res.message,
        });
        const payload = token.split('.')[1];
        const d = new Buffer.from(payload, 'base64');
        const a = d.toString();
        const iat = JSON.parse(a).iat;
        let exp = JSON.parse(a).exp;
        setTimeout(() => {
          store.dispatch(refreshToken());
        }, exp - iat - 50000);
      })
      .catch(async (error) => {
        const response = await AuthErrorHandler(error, dispatch);

        if (response === 'Not Auth') {
          dispatch({
            type: NETWORK_ERROR,
            // payload:msg
          });

          store.dispatch(userLoad(false));
        }
      });
  }
  // }, 1000 * 5);
};

//Get ATG Balance
export const getBalance = () => (dispatch) => {
  Axios.get(baseURL + '/api/user/atgwallet', tokenConfig())
    .then((res) => {
      res = res.data;

      dispatch({
        type: GET_ATG_BALANCE,
        payload: res.message,
      });
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: GET_ATG_BALANCE,
          payload: null,
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Get VT Balance
export const getVTBalance = () => (dispatch) => {
  Axios.get(baseURL + '/api/user/vtwallet', tokenConfig())
    .then((res) => {
      res = res.data;

      dispatch({
        type: GET_VT_BALANCE,
        payload: res.message,
      });
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: GET_VT_BALANCE,
          payload: null,
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Edit Product
export const editProduct = (data) => (dispatch) => {
  const body = JSON.stringify(data);

  store.dispatch(userLoad(true));
  Axios.put(baseURL + '/api/product/edit', body, tokenConfig())
    .then((res) => {
      res = res.data;

      store.dispatch(loadAllProduct());
      dispatch({
        type: EDIT_PRODUCT,
        // payload: msg
      });
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: EDIT_PRODUCT,
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Reverse Transaction
export const reverse = (data) => (dispatch) => {
  const body = JSON.stringify(data);

  store.dispatch(userLoad(true));
  Axios.post(baseURL + '/api/transactions/reverse', body, tokenConfig())
    .then((res) => {
      res = res.data;
      store.dispatch(getAllTransactions());
      dispatch({
        type: REVERSE_TRANSACTION,
        // payload: msg
      });
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: REVERSE_TRANSACTION,
          // payload: msg
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Order Education
export const buyEducationPayments = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    let res = await Axios.post(
      baseURL + '/api/transactions/education',
      body,
      tokenConfig()
    );
    res = res.data;

    const msg = {
      type: 'purchase_education_success',
      msg: res.message,
    };

    dispatch({
      type: ORDER_EDUCATION,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_education_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: ORDER_EDUCATION,
        payload: msg,
      });
      store.dispatch(userLoad(false));
    }
  }
};

//Order Electricity
export const buyElecricity = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/transactions/electricity',
      body,
      tokenConfig()
    );
    const response = request.data;
    const msg = {
      type: 'purchase_electric_success',
      msg: response.message,
    };

    dispatch({
      type: ORDER_ELECTRICITY,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_electric_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: ORDER_ELECTRICITY,
        payload: msg,
      });
      store.dispatch(userLoad(false));
    }
  }
};
//Order CableTV
export const buyCableTV = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/transactions/cabletv',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: 'purchase_cabletv_success',
      msg: response.message,
    };

    dispatch({
      type: ORDER_CABLETV,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_cabletv_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: ORDER_CABLETV,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Order Data
export const buyData = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const body = JSON.stringify(data);

    const request = await Axios.post(
      baseURL + '/api/transactions/data',
      body,
      tokenConfig()
    );

    const response = request.data;

    const msg = {
      type: 'purchase_data_success',
      msg: response.message,
    };

    dispatch({
      type: ORDER_DATA,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_data_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: ORDER_DATA,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Order Airtime
export const buyAirtime = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/transactions/airtime',
      body,
      tokenConfig()
    );
    const response = request.data;
    const msg = {
      type: 'purchase_airtime_success',
      msg: response.message,
    };

    dispatch({
      type: ORDER_AIRTIME,
      payload: msg,
    });
    store.dispatch(HandleGetUser());
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'purchase_airtime_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to connect.',
      };
      dispatch({
        type: ORDER_AIRTIME,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//LOAD PRODUCTS
export const loadAllProduct = () => (dispatch) => {
  Axios.get(baseURL + '/api/product/all', tokenConfig())
    .then((res) => {
      res = res.data;
      dispatch({
        type: LOAD_PRODUCTS,
        payload: res.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOAD_PRODUCTS,
      });
    });
};

//Load Payments
export const getAllPayments = () => (dispatch) => {
  store.dispatch(userLoad(true));
  Axios.get(baseURL + '/api/payment/all', tokenConfig())
    .then((res) => {
      res = res.data;

      dispatch({
        type: LOAD_PAYMENT,
        payload: res.message,
      });

      store.dispatch(userLoad(false));
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: LOAD_PAYMENT_ERROR,
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Delete Transaction
export const deleteTransaction = (transaction_id) => (dispatch) => {
  store.dispatch(userLoad(true));
  Axios.delete(
    baseURL + `/api/transactions/delete/${transaction_id}`,
    tokenConfig()
  )
    .then((res) => {
      res = res.data;

      dispatch({
        type: DELETE_TRANSACTION,
        payload: transaction_id,
      });

      store.dispatch(userLoad(false));
    })
    .catch(async (error) => {
      const response = await AuthErrorHandler(error, dispatch);

      if (response === 'Not Auth') {
        dispatch({
          type: DELETE_TRANSACTION_ERROR,
        });

        store.dispatch(userLoad(false));
      }
    });
};

//Load Transactions
export const getAllTransactions = () => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const request = await Axios.get(
      baseURL + '/api/transactions/all',
      tokenConfig()
    );

    const response = request.data;

    dispatch({
      type: LOAD_TRANSACTIONS,
      payload: response.message,
    });
    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      dispatch({
        type: LOAD_TRANSACTIONS_ERROR,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Get all users
export const getAllUsers = () => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const request = await Axios.get(baseURL + '/api/user/all', tokenConfig());

    const response = request.data;

    dispatch({
      type: GET_ALL_USERS,
      payload: response.message,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      dispatch({
        type: GET_ALL_USERS_ERROR,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Block a user
export const UnblockUser = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/user/unblock',
      body,
      tokenConfig()
    );

    const response = request.data;

    store.dispatch(getAllUsers());

    const msg = {
      type: 'block_success',
      msg: response.message,
    };
    dispatch({
      type: BLOCK_DONE,
      payload: msg,
    });
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'block_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to un-block the user.',
      };
      dispatch({
        type: BLOCK_DONE,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Block a user
export const blockUser = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/user/block',
      body,
      tokenConfig()
    );

    const response = request.data;

    store.dispatch(getAllUsers());
    const msg = {
      type: 'block_success',
      msg: response.message,
    };
    dispatch({
      type: BLOCK_DONE,
      payload: msg,
    });
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: 'block_fail',
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to block the user.',
      };
      dispatch({
        type: BLOCK_DONE,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  }
};

//Reset Pin
export const resetSecretPin = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    await Axios.put(baseURL + '/api/user/resetpin', body, config);

    const msg = {
      type: RESET_PIN_PASS,
      msg: 'Secret pin Changed Successfully.',
    };
    dispatch({
      type: RESET_PIN_DONE,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);
    store.dispatch(userLoad(false));
    if (response === 'Not Auth') {
      const msg = {
        type: RESET_PIN_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'Oops!! a problem occured while trying to reset your secret pin.',
      };
      return dispatch({
        type: RESET_PIN_DONE,
        payload: msg,
      });
    }
  }
};

//Reset Password
export const resetPassword = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const body = JSON.stringify(data);
    const request = await Axios.put(
      baseURL + 'auth/reset-password',
      body,
      config
    );

    const msg = {
      type: 'reset_done',
      msg: 'Password Changed Successfully.',
    };
    dispatch({
      type: RESET_TOKEN_VERIFIED,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    store.dispatch(userLoad(false));
    const msg = {
      type: 'reset_verification_fail',
      msg: error.response
        ? error.response.data.data.message
        : 'Oops!! your password cannot be changed at this moment.',
    };
    dispatch({
      type: RESET_TOKEN_VERIFIED,
      payload: msg,
    });
  }
};

export const HANDLE_VERIFY_RESET_TOKEN = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + 'auth/verify-reset-token',
      body,
      config
    );

    const msg = {
      type: 'reset_verification_pass',
    };
    dispatch({
      type: RESET_TOKEN_VERIFIED,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    store.dispatch(userLoad(false));
    const msg = {
      type: 'reset_verification_fail',
      msg: error.response
        ? error.response.data.data.message
        : 'Oops!! your password cannot be changed at this moment.',
    };
    dispatch({
      type: RESET_TOKEN_VERIFIED,
      payload: msg,
    });
  }
};

//Forgot pasword
export const forgotPassword = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    await Axios.post(baseURL + '/api/auth/initiatereset', body, config);

    const msg = {
      type: INITIATE_RESET_SUCCESS,
      msg: '',
    };

    dispatch({
      type: INITIATE_RESET_SUCCESS,
      payload: msg,
    });
    store.dispatch(userLoad(false));
  } catch (error) {
    const msg = {
      type: INITIATE_RESET_FAIL,
      msg: error.response
        ? error.response.data.message
        : 'Oops!! your password cannot be changed at this moment.',
    };
    dispatch({
      type: INITIATE_RESET_FAIL,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  }
};

//Verify Payment

export const verifyPayment = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);

    await Axios.post(baseURL + '/api/payment/verify', body, tokenConfig());

    const msg = {
      type: 'verification_done',
    };
    dispatch({
      type: VERIFICATION_DONE,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const msg = {
      type: 'verification_done',
    };
    dispatch({
      type: VERIFICATION_DONE,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  }
};

//Initiate Payment
export const initiatePayment = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.post(
      baseURL + '/api/payment/initiate',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: PAYMENT_INITIATION_SUCCESS,
      link: response.message,
    };
    dispatch({
      type: PAYMENT_INITIATION_SUCCESS,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);
    store.dispatch(userLoad(false));
    if (response === 'Not Auth') {
      const msg = {
        type: PAYMENT_INITIATION_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'Oops! there has been an error.',
      };
      dispatch({
        type: PAYMENT_INITIATION_FAIL,
        payload: msg,
      });
    }
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);
    const request = await Axios.put(
      baseURL + '/api/user/changepassword',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: CHANGE_PASS_SUCCESS,
      msg: response.message,
    };
    dispatch({
      type: CHANGE_PASS_SUCCESS,
      payload: msg,
    });
    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);
    store.dispatch(userLoad(false));
    if (response === 'Not Auth') {
      const msg = {
        type: CHANGE_PASS_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'Oops! there has been an error.',
      };
      dispatch({
        type: CHANGE_PASS_FAIL,
        payload: msg,
      });
    }
  }
};

const AuthErrorHandler = async (error, dispatch) => {
  if (error.response && error.response.status === 403) {
    /*Log the user out */
    const msg = {
      type: LOGIN_FAIL,
      msg: 'Your session has expired, please login again.',
    };
    return dispatch({
      type: AUTH_ERROR,
      payload: msg,
    });
  }

  if (error.response && error.response.status === 401) {
    /*Log the user out */
    const msg = {
      type: LOGIN_FAIL,
      msg: '',
    };
    return dispatch({
      type: AUTH_ERROR,
      payload: msg,
    });
  }

  // if (error.response && error.response.status === 423) {
  //     /*Log the user out */
  //     const msg = {
  //         type: LOGIN_FAIL,
  //         msg: error.response ? error.response.data.message : 'opps! a problem occured.'
  //     }
  //     return dispatch({
  //         type: AUTH_ERROR,
  //         payload: msg
  //     });
  // }
  else {
    return 'Not Auth';
  }
};

/*Share Funds*/
export const shareWallet = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);

    const request = await Axios.post(
      baseURL + '/api/transactions/share',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: SHARE_SUCCESS,
      msg: 'Funds Sent Successfully.',
      amount: response.message,
    };
    dispatch({
      type: SHARE_SUCCESS,
      payload: msg,
    });

    store.dispatch(userLoad(false));
    store.dispatch(HandleGetUser());
  } catch (error) {
    store.dispatch(userLoad(false));
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: SHARE_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'Oops!! there has been an error.',
      };
      dispatch({
        type: SHARE_FAIL,
        payload: msg,
      });
    }
  }
};

///RE-Authenticate User
export const authenticateUserFromToken = () => (dispatch) => {
  let token = localStorage.getItem('token');
  let rToken = localStorage.getItem('refresh_token');
  // console.log(token)
  if (token !== undefined || token !== null) {
    store.dispatch(userLoad());

    setTimeout(() => {
      store.dispatch(HandleGetUser());
      let data = {
        token,
        rToken,
      };
      dispatch({
        type: AUTH_USER,
        payload: data,
      });
    }, 1000);
  } else {
    // const msg = {
    //     type: 'login_fail',
    //     msg: 'Please re-login, your session has expired.'
    // }
    // dispatch( {
    //     type: AUTH_ERROR,
    //     payload: msg,
    // } )
  }
};

//Loading User
export const userLoad =
  (choice = false) =>
  (dispatch) => {
    dispatch({
      type: USER_LOADING,
      payload: choice,
    });
  };

//Logout
export const HandleLogout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_SUCCESS,
    });

    //Get Token
    const Rtoken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');

    let body = JSON.stringify({ name: 'wewerewr' });
    config.headers['x-refresh-token'] = Rtoken;
    config.headers['x-auth-token'] = token;
    await Axios.post(baseURL + '/api/auth/logout', body, config);
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: null,
    });
  }
};

export const CREATE_SECRET_PIN = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));
    const body = JSON.stringify(data);

    const request = await Axios.put(
      baseURL + '/api/auth/create_secret_pin',
      body,
      tokenConfig()
    );

    const response = request.data;
    const msg = {
      type: CREATE_PIN_SUCCESS,
      msg: response.message,
    };
    dispatch({
      type: CREATE_PIN_SUCCESS,
      payload: msg,
    });

    store.dispatch(userLoad(false));
    store.dispatch(HandleGetUser());
  } catch (error) {
    store.dispatch(userLoad(false));
    const response = await AuthErrorHandler(error, dispatch);

    if (response === 'Not Auth') {
      const msg = {
        type: CREATE_PIN_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'Oops!! there has been an error.',
      };
      dispatch({
        type: CREATE_PIN_FAIL,
        payload: msg,
      });
    }
  }
};

/*Get User details*/
export const HandleGetUser = () => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const request = await Axios.get(baseURL + '/api/user', tokenConfig());

    const response = request.data;
    dispatch({
      type: USER_LOADED,
      payload: response.message,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const response = await AuthErrorHandler(error, dispatch);
    store.dispatch(userLoad(false));

    if (response === 'Not Auth') {
      return dispatch({
        type: NETWORK_ERROR,
        payload: null,
      });
    }
  }
};

/*Login*/
export const HandleLogin =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      store.dispatch(userLoad(true));

      const body = JSON.stringify({ username, password });

      const request = await Axios.post(
        baseURL + '/api/auth/login',
        body,
        config
      );

      const response = request.data;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
      });
      let token = response.JWTtoken;
      const payload = token.split('.')[1];
      const d = new Buffer.from(payload, 'base64');
      const a = d.toString();
      const iat = JSON.parse(a).iat;
      let exp = JSON.parse(a).exp;
      setTimeout(() => {
        store.dispatch(refreshToken());
      }, exp - iat - 50000);

      store.dispatch(userLoad(false));
    } catch (error) {
      const msg = {
        type: LOGIN_FAIL,
        msg: error.response
          ? error.response.data.message
          : 'opps! a problem occured.',
      };
      dispatch({
        type: LOGIN_FAIL,
        payload: msg,
      });

      store.dispatch(userLoad(false));
    }
  };

/*Verify Email*/
export const HandleVerifyEmail = (data) => async (dispatch) => {
  try {
    store.dispatch(userLoad(true));

    const body = JSON.stringify(data);
    const request = await Axios.put(
      baseURL + 'auth/verify-email',
      body,
      config
    );

    const response = request.data;
    const msg = {
      type: VERIFICATION_PASS,
      msg: response.data.message,
    };
    dispatch({
      type: VERIFICATION_DONE,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const msg = {
      type: VERIFICATION_FAIL,
      msg: error.response
        ? error.response.data.data.message
        : 'opps! a problem occured.',
    };
    dispatch({
      type: VERIFICATION_DONE,
      payload: msg,
    });
    store.dispatch(userLoad(false));
  }
};

/*Register*/
export const HandleRegistration = (data) => async (dispatch) => {
  const body = JSON.stringify(data);

  try {
    store.dispatch(userLoad(true));

    const request = await Axios.post(
      baseURL + '/api/auth/register',
      body,
      config
    );

    const response = request.data;

    const msg = {
      type: REGISTER_SUCCESS,
      msg: response.message,
    };
    dispatch({
      type: REGISTER_SUCCESS,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  } catch (error) {
    const msg = {
      type: REGISTER_FAIL,
      msg: error.response
        ? error.response.data.message
        : 'opps! a problem occured.',
    };
    dispatch({
      type: REGISTER_FAIL,
      payload: msg,
    });

    store.dispatch(userLoad(false));
  }
};
