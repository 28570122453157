import React, {useState,useEffect} from 'react'
import './Airtime.css';
import './mobile.css';
import { buyAirtime} from '../../../../actions/Action';
import store from '../../../../store'
import {connect} from 'react-redux'
import Utils from '../../../Utils/Functions'
import { HandleVerifyCoupon } from '../../../../actions/public';
import { VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS } from '../../../../actions/public/types';
const Airtime = ({errorMessage,user,networks,server_message}) => {

    
    const [ network, setNetwork ] = useState();
    const [ checked, setChecked ] = useState( false );
    const [ amount, setAmount ] = useState( '' );
    const [ pin, setPin ] = useState( '' );
    const [phoneNumber, setPhone] =useState('')
    const [ msg, setMsg] = useState( '' );
    const [allNetworks,setAllNetwork] = useState([])
    const [ couponCode, setCouponCode ] = useState( '' )
    const [showCouponCodeField,setShowCouponCodeField] =useState(true)
    const [discountAmount, setDiscountAmount]  = useState(0);
    const [discount, setDiscount] = useState('')

    //Check Error Message
    useEffect( () => {
        if ( errorMessage && errorMessage.type === 'purchase_airtime_fail' ) {
            setMsg( errorMessage.msg )
            Utils.closePopUp();
        } else if(errorMessage && errorMessage.type === 'purchase_airtime_success'){
            setMsg( errorMessage.msg );
            setPin( '' );
            setAmount( '' )
            setPhone('')
            Utils.closePopUp();
        }
        
        if ( server_message.type === VERIFY_COUPON_FAIL ) {
            setMsg( server_message.info )
        }
        
        if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'AIRTIME' ) {
            setShowCouponCodeField(false)
            setAmount(server_message.info.meta_data.amount)
            
        }else if(server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'AIRTIME'){
            setMsg('Invalid coupon code supplied.')
        }

        return ()=>console.log('')
    },[errorMessage,server_message])

    

    const orderAirtime = ( e ) => {
        // e.preventDefault()
        let firstThree = phoneNumber.slice( 0, 3 );

        const numberArray = [ '070', '081', '080', '090','091' ]
        const indexOfFirstThreeNumber = numberArray.indexOf( firstThree );
        const lenthOfPhoneNumber = phoneNumber.length === 11;
        const space = phoneNumber.match( /\s/g );
        const non_digit = phoneNumber.match( /\D/g );
        const aplha = phoneNumber.match( /[a-zA-Z]/g );
        const amountAlpha = amount.match( /[a-zA-Z]/g );
        const amountSpace = amount.match( /\s/g );
        const Amountnon_digit = amount.match( /\D/g );

        if ( amount ) {
            if ( parseFloat(amount.replace(/₦/g,'').replace(/,/g,'')) > parseFloat(user.wallet_balance) ) {
                return setMsg('Insufficient funds.')
            }
            if ( phoneNumber && amount
                && indexOfFirstThreeNumber !== -1
                && !space && !non_digit && !aplha
                && lenthOfPhoneNumber && !amountAlpha
                && !amountSpace && !Amountnon_digit
                // && checked
                && parseInt( amount ) > 50 ) {
               
                if ( pin ) {
                    setMsg( '' );
            let data = {
                pin,
                amount,
                phone_number:phoneNumber,
                coupon_code:couponCode
            }
            //  return console.log(data)
            return store.dispatch(buyAirtime(data))
                } else {
                  return  setMsg( 'Pin Required.' );
                }
               
            }
            if ( parseInt( amount.replace( /₦/g, '' ).replace( /,/g, '' ) ) < 100 ) {
                return  setMsg( 'Minimum amount of ₦100 can be recharged.' );
                
            }
            else {
             return   setMsg( 'Invalid Phone Number or Amount.' )
            }
        }else {
            return setMsg('Please make sure all inputs are valid.')
        }

        
        
    };



    
    useEffect( () => {
        
        setAllNetwork(networks);

        return ()=>console.log('');

    }, [ networks ] );


    useEffect(() => {
      if(network){
        const n =  allNetworks.find(net=> net.name === network);
        setDiscount(n.wallet_percent_off);
      }
        return () => {
            console.log('')
        }
    }, [network]);


    useEffect(() => {
        if(discount && parseInt(amount)){
            setDiscountAmount(parseInt(parseInt(amount) - ((parseInt(discount) / 100) * parseInt(amount))))
        }
        return () => {
            console.log('')
        }
    }, [amount,network])
    

    const modalLeft = () => {
        return (
            <div className="modal-left">
                <form>
                    <h5>{ msg }</h5>
                    <div className="network">
                        <label>Select Network</label>
                        <select value={network} onChange={ ( e ) => setNetwork( e.target.value ) }>
                            <option selected disabled hidden >select network</option>
                            { allNetworks ? allNetworks.map( ( net,i ) => (
                                <>
                                
                                    <option key={i}>{ net.name }</option>
                                </>
                            ) ) : <option >select network</option> }

                        </select>
                    </div>
                    <div className="amount">
                        <label>Enter Amount</label>
                        <input value={amount} onChange={ ( e ) => setAmount( e.target.value ) } />
                    </div>
                    <div className="amount">
                        <label>You will pay</label>
                        <input disabled={true}  value={discountAmount} />
                    </div>
                    <div className="phone-number">
                        <label>Enter Phone Number</label>
                        <input value={phoneNumber} onChange={ ( e ) => setPhone( e.target.value ) } />
                    </div>
                    <div className="pin">
                        <label>Transaction Pin</label>
                        <input value={pin} onChange={ ( e ) => setPin( e.target.value ) } />
                    </div>

                    {/* <div className="check">
                        <p onClick={ ( e ) => updateCheck( e ) }><i className="fa fa-check checkbox"></i></p>
                        <label>I have confirmed the phone number.</label>
                        
                    </div> */}
                </form>

                <p onClick={ (e) => orderAirtime(e) }>Order Now</p>
            </div>
        )
    };
    const loadCoupon = ( e ) => {
        // e.preventDefault()
        if ( !couponCode ) {
          return  setMsg('Coupon code cannot be blank.')
        }
        let data = {
            coupon_code:couponCode
        }
        setMsg('')
        
        store.dispatch(HandleVerifyCoupon(data))

    }

    const modalRight = () => {
        return (
            <div className="modal-right">
                { !showCouponCodeField ?
                    <>
                         <form>
                    <h5>{ msg}</h5>
                    <div className="network">
                        <label>Select Network</label>
                        <select value={network} onChange={ ( e ) => setNetwork( e.target.value ) }>
                            <option selected disabled hidden >select network</option>
                            { allNetworks ? allNetworks.map( ( net,i ) => (
                                <>
                                
                                    <option key={i}>{ net.name }</option>
                                </>
                            ) ) : <option >select network</option> }

                        </select>
                    </div>
                    
                    <div className="amount">
                        <label>Enter Amount</label>
                        <input disabled={true}  value={amount} />
                    </div>
                    
                    <div className="phone-number">
                        <label>Enter Phone Number</label>
                        <input value={phoneNumber} onChange={(e)=>setPhone(e.target.value)} />
                    </div>
                     <div className="pin">
                        <label>Transaction Pin</label>
                        <input value={pin} onChange={(e)=>setPin(e.target.value)} />
                    </div>

                    {/* <div className="check">
                        <p onClick={(e)=>updateCheck(e)}><i className="fa fa-check checkbox"></i></p>
                        <label>I have confirmed the phone number.</label>
                        
                    </div> */}
                </form>

                <p onClick={(e)=>orderAirtime(e)}>Order Now</p>
                    </>
                    :
                    <>
                         <form>
                    <h5>{ msg}</h5>
                    <div>
                    <div className="coupon-code">
                        <label>Enter coupon code</label>
                        <input onChange={ ( e ) => setCouponCode( e.target.value ) } value={couponCode} />
                    </div>
                    </div>
                        </form>
                        <p onClick={(e)=>loadCoupon(e)} >Load</p>
                    </>
                    }
            </div>
        )
    }

    const handleChangeForm = ( { e, className } ) => {
        const modalRight = document.getElementsByClassName('modal-right')
        const modalLeft = document.getElementsByClassName('modal-left')
        setShowCouponCodeField(true)
        setMsg('')
        if ( className === 'right' ) {
            //Remove Active class from left
            e.currentTarget.previousElementSibling.classList.remove('left-active')
            e.currentTarget.classList.add( 'right-active' );
            modalLeft[ 0 ].style.display = 'none';
            modalRight[0].style.display = 'flex'
        }


        if ( className === 'left' ) {
            //Remove Active class from right
            e.currentTarget.nextElementSibling.classList.remove('right-active')
            e.currentTarget.classList.add( 'left-active' );
            modalLeft[ 0 ].style.display = 'flex';
            modalRight[0].style.display = 'none'
        }
    }

    return (
        <section className="airtime">
            <h3>Airtime</h3>
            <div className="wrap">
            <div className="header">
                <h4 onClick={(e)=>{handleChangeForm({e,className:'left'})}} className="left left-active">Use wallet</h4>
                    <h4  onClick={(e)=>{handleChangeForm({e,className:'right'})}} className="right">Use Coupon</h4> 
                </div>
                <div className="form">
                    { modalLeft() }
                    {modalRight()}
                </div>
                
            </div>
        </section>
    )
};

const mapStateToProps =( { data:{errorMessage,user},public:{networks,server_message} })=>({
    errorMessage,user,networks,server_message
})

export default connect(mapStateToProps, null)(Airtime)
