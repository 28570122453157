import React from 'react';
import './Privacy.css';
import './Mobile.css';

const Privacy = () => {
  return (
    <section className='privacy'>
      <div className='image'></div>
      <div className='image-overlay'>
        <h1>Privacy Policy</h1>
      </div>

      <div className='privacy-content'>
        <p className='intro'>
          CreditSync respects the privacy of its users and is committed to
          protecting the personal information that you share with us. This
          privacy policy explains how we collect, use, and share your personal
          information.
        </p>

        <p className='question'>What personal information do we collect?</p>

        <p>We collect the following personal information from you:</p>

        <ul>
          <li>Your name</li>
          <li>Your email address</li>
          <li>Your phone number</li>
        </ul>
        <p>
          We collect this information when you register for our website, create
          an account, make a purchase, or contact us for support.
        </p>

        <p className='question'>How do we use your personal information?</p>

        <p>
          We use your personal information to provide you with the services that
          you have requested, such as:
        </p>

        <ul>
          <li>Processing your payments</li>
          <li>Providing you with customer support</li>
        </ul>

        <p>
          We also use your personal information to communicate with you. We may
          send you service-related emails or messages (e.g., account
          verification, payment confirmations, technical and security notices)
          even if you have not opted-in to receive marketing communications from
          us.
        </p>

        <p className='question'>
          Who do we share your personal information with?
        </p>

        <p>
          We share your personal information with the following third parties:
        </p>

        <ul>
          <li>Our payment processor</li>
          <li>Our email service provider</li>
          <li>Our analytics service provider</li>
        </ul>

        <p>
          We may also share your personal information with law enforcement
          agencies, public authorities, or other third parties if we are
          required to do so by law, or if we believe in good faith that
          disclosure is reasonably necessary to (1) investigate, prevent, or
          take action regarding suspected or actual illegal activities or to
          assist government enforcement agencies; (2) enforce our agreements
          with you; (3) investigate and defend ourselves against any third-party
          claims or allegations; (4) protect the security or integrity of our
          website; or (5) exercise or protect the rights and safety of
          CreditSync, our users, personnel, or others.
        </p>

        <p className='question'>How do we protect your personal information?</p>

        <p>
          We implement a variety of security measures to protect the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information.
        </p>

        <ul>
          <li>
            We use encryption to protect your personal information when
            appropriate.
          </li>
          <li>
            We use firewalls to protect your personal information from
            unauthorized access.
          </li>
          <li>
            We limit access to your personal information to those who need to
            know.
          </li>
        </ul>

        <p className='question'>
          How long do we keep your personal information?
        </p>

        <p>
          We retain your personal information for as long as necessary to
          provide the services that you have requested, or for other essential
          purposes such as complying with our legal obligations, resolving
          disputes, and enforcing our agreements. Because these needs can vary
          for different data types in the context of different products or
          services, actual retention periods can vary significantly based on
          criteria such as user expectations or consent, the sensitivity of the
          data, the availability of automated controls that enable users to
          delete data, and our legal or contractual obligations.
        </p>

        <p>Changes to this privacy policy</p>

        <p>
          We may update this privacy policy from time to time. We encourage you
          to periodically review this privacy policy to stay informed about our
          privacy practices. Your continued use of our website constitutes your
          agreement to this privacy policy and any updates.
        </p>

        <p>
          If you have any questions about this privacy policy, please contact us
          at support@creditsync.com.ng
        </p>
      </div>
    </section>
  );
};

export default Privacy;
