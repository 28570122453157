import React,{useState,useEffect} from 'react'
import './style.css'
import './mobile.css'
import Utils from '../../../../Utils/Functions'
import store from '../../../../../store';
import { connect } from 'react-redux';
import {buyCoupon } from '../../../../../actions/Action'
const Buy = ( { errorMessage,user,electricity_variations,education_variations,cabletv_variations,data_variations} ) => {
    const [ product, setProduct ] = useState( '' );
    const [ airtimeAmount, setAirtimeAmount ] = useState( '' )
    const [ msg, setMsg ] = useState( '' )
    const [ pin, setPin ] = useState( '' );
   
    const [ unit, setUnit ] = useState( 1 );
    const [ totalAmount, setTotalAmount ] = useState( 0 )
    const availableProducts = [ 'Airtime', 'Data', 'CableTV', 'Electricity' ];


    //Check Error Message
    useEffect( () => {
         
        if ( errorMessage && errorMessage.type === 'purchase_coupon_fail' ) {
            setMsg( errorMessage.msg );
            window.scrollTo( 0, 0 );
            // Utils.closePopUp();
        } else if ( errorMessage && errorMessage.type === 'purchase_coupon_success' ) {
            setMsg( errorMessage.msg );
            window.scrollTo( 0, 0 );
            // Utils.closePopUp();
        }
        return () => console.log('')
    }, [ errorMessage ] );

    /*  FOR DATA */

    const [ plans, setPlans ] = useState([]);
    const [ planId, setplanId ] = useState( '' );
    const [ dataSubriptions, setDataSubcriptions ] = useState( [] );
    const [ selectedNetwork, setSelectedNetwork ] = useState();
    const [ networkId, setNetworkId ] = useState( '' );
    const [ serviceId, setServiceId ] = useState( '' );
    const [ dataAmount, setDataAmount ] = useState( '' )

    useEffect( () => {
        
        
        setDataSubcriptions( data_variations );
        return () => console.log('')
        
    }, [ electricity_variations ] )

    useEffect( () => {
        if ( dataSubriptions.length !== 0 ) {
            setSelectedNetwork( dataSubriptions[ 0 ].name );
            setNetworkId( dataSubriptions[ 0 ].network_id );
            setServiceId( dataSubriptions[ 0 ].service_id );
        }
        return () => console.log('')
    }, [ dataSubriptions ] )



    //Update Data Plans to Show
    useEffect( () => {
        if ( selectedNetwork ) {
            const dataPlans = dataSubriptions.filter( d => d.name === selectedNetwork );
            if ( dataPlans.length !== 0 ) {
                
                setPlans( dataPlans[ 0 ].plans );
                setNetworkId( dataPlans[ 0 ].network_id );
                setServiceId( dataPlans[ 0 ].service_id );

            } else {
                setPlans()
            }
            
        }
        return () => console.log('')
    }, [ selectedNetwork ] );

    const updateAmount = ( e ) => {
        const value = e.target.value;
       
        
       
        let amount = plans.find( plan => plan.id === e.currentTarget.selectedOptions[ 0 ].id );
        if ( amount ) {
            setDataAmount( Utils.formatCurrency( parseInt( amount.wallet_price ) ) );
            setplanId( amount.plan_id )
        }
        
        
    }

    useEffect( () => {
        if ( plans && plans.length !== 0 ) {
            setDataAmount( Utils.formatCurrency( parseInt( plans[ 0 ].wallet_price ) ) );
            setplanId( plans[ 0 ].plan_id )
        } else {
            setDataAmount( '' );
            setplanId( '' )
        }

        return () => console.log('')
    }, [ plans ] );
    const returnData = () => {
        return (
            <React.Fragment>
                <div>
                    <label>Select Network</label>
                    <select value={ selectedNetwork } onChange={ ( e ) => setSelectedNetwork( e.target.value ) }>
                        { ( dataSubriptions.length > 0 ) ? dataSubriptions.map( ( network ) => (
                            <option key={ network.id }>{ network.name }</option>
                        ) ) : <option>Select Network</option> }
                                
                    </select>
                </div>
                <div>
                    <label>Select Plan</label>
                    <select onChange={ ( e ) => updateAmount( e ) }  >
                       
                        { ( plans ) ? plans.map( p => (
                            <option id={ p.id } plan_id={ p.plan_id } key={ p.id }>{ p.name.toUpperCase() }</option>
                        ) ) : <option>Select Plan</option> }
                    </select>
                </div>
                <div>
                    <label>Amount</label>
                    <input disabled={ true } value={ dataAmount } />
                </div>
            </React.Fragment>
        )
    };
    /* END OF DATA */

    /** For Education */
     const [ eduAmount, setEduAmount ] = useState();
    const [ eduPlans, setEduPlans ] = useState();
    const [ eduPlanId, setEduPlanId ] = useState('');
    // const [ plan, setPlan ] = useState( '' );
    // const [ msg, setMsg ] = useState( '' );
    const [ eduServiceId, setEduServiceId ] = useState( '' );
    const [ selectedType, setSelectedType ] = useState( '' );
    const [ educationPlans, seteducationPlans ] = useState( [] );
    const [eduConvFee, setEduConvFee] = useState();
     const [ amountToPay, setAmountToPay ] = useState( 0 );
        useEffect( () => {
        
        
        seteducationPlans( education_variations );
        return () => console.log('')
    }, [ education_variations ] );

     useEffect( () => {
        if ( educationPlans.length !== 0 ) {
            setSelectedType( educationPlans[ 0 ].name );
            setEduServiceId ( educationPlans[ 0 ].service_id );
            setEduConvFee(educationPlans[0].wallet_convinience_fee)
        };
        return () => console.log('')
    }, [ educationPlans ] );
    //Update Data Plans to Show
    useEffect( () => {
        if ( selectedType ) {
            const plans = educationPlans.filter( d => d.name === selectedType );
            if ( plans.length !== 0 ) {
                
                setEduPlans( plans [ 0 ].plans );
                setEduServiceId ( plans [ 0 ].service_id );
                setEduConvFee(plans [0].wallet_convinience_fee)
            } else {
                setEduPlans()
            }
            
        }
        return () => console.log('')
    }, [ selectedType ] );

    const updateEduAmount = ( e ) => {
        const value = e.target.value;
       
        
       
        let amount = eduPlans.filter( plan => plan.plan_id === e.currentTarget.selectedOptions[ 0 ].id );
        if ( amount && amount.length !== 0 ) {
            setEduAmount( Utils.formatCurrency( parseInt( amount[ 0 ].price ) ) );
            setEduPlanId( amount[ 0 ].plan_id );
            setAmountToPay( Utils.formatCurrency( (parseFloat( eduPlans[ 0 ].price )  * unit )+ parseFloat( convFee )) );
        }
        
        return () => console.log('')
    }

    useEffect( () => {
        if ( eduPlans && eduPlans.length !== 0 ) {
            setEduAmount( Utils.formatCurrency( parseInt( eduPlans[ 0 ].price ) ) );
            setEduPlanId( eduPlans[ 0 ].plan_id );
            setAmountToPay(Utils.formatCurrency( (parseFloat( eduPlans[ 0 ].price )  * unit )+ parseFloat( convFee )));
        } else {
            setEduAmount( '' );
            setEduPlanId( '' )
        }
        return () => console.log('')
    }, [ eduPlans,unit ] );

    const returnEducation =()=>{
        return(
            <React.Fragment>
                <div>
                        <label>Select Type</label>
                        <select value={ selectedType } onChange={ ( e ) => setSelectedType( e.target.value ) }>
                            { ( educationPlans.length > 0 ) ? educationPlans.map( ( edu ) => (
                                <option key={ edu.id }>{ edu.name }</option>
                            ) ) : <option>Select Type</option> }
                                
                        </select>
                    </div>
                    <div>
                        <label>Select Item</label>
                        <select onChange={ ( e ) => updateEduAmount( e ) }  >
                       
                            { ( eduPlans ) ? eduPlans.map( p => (
                                <option id={ p.plan_id } plan_id={ p.plan_id } key={ p.plan_id }>{ p.name.toUpperCase() }</option>
                            ) ) : <option>Select Plan</option> }
                        </select>
                        
                    </div>
                    <div>
                        <label>Amount</label>
                        <input disabled={ true } value={ eduAmount } />
                    </div>
                    
            </React.Fragment>
        );
    };

    /** END OF EDU */

    /** For CableTV */
    const [ cablePlans, setCablePlans ] = useState( [] );
    const [ cableAmount, setCableAmount ] = useState();
    const [ CablePlanId, setCablePlanId ] = useState( '' );
    const [ tvs, setTvs ] = useState( [] );
    const [ selectedTV, setSelectedTV ] = useState();
    
    const [ convFee, setConvFee ] = useState()
    const [ cableServiceId, setCableServiceId ] = useState( '' );
    useEffect( () => {
        
        setTvs(cabletv_variations)
    
        return () => console.log('')
    }, [ cabletv_variations ] );
    useEffect( () => {
        if ( tvs && tvs.length !== 0 ) {
            setSelectedTV( tvs[ 0 ].name )
            setConvFee( tvs[ 0 ].convinience_fee )
        };

        return () => console.log('')
    }, [ tvs ] );
    //Update Cable Plans to Show
    useEffect( () => {
        if ( selectedTV ) {
            const tvPlans = tvs.filter( d => d.name === selectedTV );
            if ( tvPlans.length !== 0 ) {
                setConvFee( tvPlans[ 0 ].wallet_convinience_fee )
                setCablePlans( tvPlans[ 0 ].plans )
                setCableServiceId( tvPlans[ 0 ].service_id )
            } else {
                setCablePlans()
            }
            
        };

        return () => console.log('')
    }, [ selectedTV ] );
    //Set Amount
    useEffect( () => {
        if ( cablePlans && cablePlans.length !== 0 && cablePlans[ 0 ].price ) {
            setCableAmount( Utils.formatCurrency( parseInt( cablePlans[ 0 ].price ) ) );
           
            setTotalAmount( Utils.formatCurrency( ( parseInt( cablePlans[ 0 ].price ) * unit + ( parseFloat( convFee ) * unit ) ) ) )
            
            setCablePlanId( cablePlans[ 0 ].plan_id )
        } else {
            setCableAmount( '' );
            setCablePlanId( '' )
        }

        return () => console.log('')
    }, [ cablePlans ] );
    //Update AMount
    const updateCableAmount = ( e ) => {
        const value = e.target.value;
        const splittedValue = value.split( '-' )
        // console.log(value)
        // console.log(value.split('-'));
        setCablePlanId( e.target.selectedOptions[ 0 ].id )
        
        let amount = cablePlans.filter( plan => plan.plan_id === e.target.selectedOptions[ 0 ].id );
        if ( amount && amount.length !== 0 && amount[ 0 ].price ) {
            setCableAmount( Utils.formatCurrency( parseInt( amount[ 0 ].price ) ) );
            
            setTotalAmount( Utils.formatCurrency( ( parseInt( amount[ 0 ].price ) * unit + ( parseFloat( convFee ) * unit ) ) ) )
            
            setCablePlanId( e.target.selectedOptions[ 0 ].id )
        }

    };


    const returnCable = () => {
        return (
            <React.Fragment>
                <div className="selectect-tv">
                    <label>Select TV</label>
                    <select value={ selectedTV } onChange={ e => setSelectedTV( e.target.value ) }>
                        { ( tvs.length > 0 ) ?
                            tvs.map( tv => (
                                <option key={ tv.id }>{ tv.name.toUpperCase() }</option>
                            ) )
                            : <option>select TV</option> }
                    </select>
                </div>
                <div className="select-plans">
                    <label>select plan</label>
                    <select onChange={ e => updateCableAmount( e ) }  >
                       
                        { ( cablePlans.length > 1 ) ? cablePlans.map( p => (
                            <option plan_id={p.plan_id} id={ p.plan_id } key={ p.plan_id }>{ p.name.toUpperCase() }</option>
                        ) ) : <option>Select Plan</option> }
                    </select>
                </div>
                <div>
                    <label>Amount</label>
                    <input disabled={ true } value={ cableAmount } />
                </div>
            </React.Fragment>
        )
    }

    /**End of Cable */
    
    /**For Electricity */
    const [ eAmount, setEAmount ] = useState( 0 );
    const [ eConvFee, setEConvFee ] = useState()
    const [ eConvFeeee, setEConvFeeee ] = useState()
    useEffect( () => {
        if ( electricity_variations.length !== 0 ) {
           
            setEConvFee( electricity_variations[ 0 ].wallet_convinience_fee )
        }
    }, [ electricity_variations ] );
    useEffect( () => {
        if ( eAmount && eAmount > 0 ) {
            if(eAmount >= 1000){
                setTotalAmount( Utils.formatCurrency( parseInt( 100  + parseInt( eAmount )   ) * parseInt(unit) ) );
            }else{
                setTotalAmount( Utils.formatCurrency( parseInt(( ( parseFloat( eConvFee ) / 100 ) * parseInt( eAmount ) )  + parseInt( eAmount ) ) * parseInt(unit)  ) );
            }
            
        }
    }, [ eAmount ] );

    // useEffect( () => {
    //     if ( unit > 5 ) {
    //         setEConvFeeee( eConvFee - 2 )
    //     } else {
    //         setEConvFeeee( eConvFee )
    //     }
    // }, [ unit, eAmount, eConvFee, eConvFeeee ] )
    const returnElectricity = () => {
        return (
            <React.Fragment>
                <div className="amount">
                    <label>Amount</label>
                    <input onChange={ ( e ) => setEAmount( e.target.value ) } value={ eAmount } />
                </div>
            </React.Fragment>
        )
    }
    /**End of Electricity */
    const returnAirtime = () => {
        return (
            <React.Fragment>
                <div className="amount">
                    <label>Enter Amount</label>
                    <input value={ airtimeAmount } onChange={ ( e ) => setAirtimeAmount( e.target.value ) } />
                </div>
                
            </React.Fragment>
        )
    }

    const updateUnit = ( sign ) => {
        if ( sign === 'minus' ) {
            // let u = unit;
            if ( unit > 1 ) {
                setUnit( unit - 1 )
            }
        }

        if ( sign === 'plus' ) {
            setUnit( unit + 1 )
        }
    }

    useEffect( () => {
        if ( product === 'Airtime' ) {
            setTotalAmount( Utils.formatCurrency( airtimeAmount ? parseInt( airtimeAmount ) * unit : 0 ) )
            
        } else if ( product === 'Data' && dataAmount ) {
            setTotalAmount( Utils.formatCurrency( parseInt( dataAmount.replace( /₦/g, '' ).replace( /,/g, '' ) ) * unit ) )
        } else if ( product === 'CableTV' && cableAmount ) {
           
            setTotalAmount( Utils.formatCurrency( ( parseInt( cableAmount.replace( /₦/g, '' ).replace( /,/g, '' ) ) * unit + ( parseFloat( convFee ) * unit ) ) ) );
                
            
        } else if ( product === 'Electricity' ) {
            
            const percentage = Math.round(((parseFloat(eConvFee) / 100) * parseInt(eAmount)));

            setTotalAmount( Utils.formatCurrency( eAmount   && eAmount < 1000 ? parseInt( (percentage + parseInt( eAmount ) ) * unit  ) : eAmount && eAmount >= 1000 ? parseInt( 100 + parseInt( eAmount )  ) * parseInt(unit): 0  ) )
        }else if ( product === 'Education' ) {
            setTotalAmount( Utils.formatCurrency( ( parseInt( eduAmount.replace( /₦/g, '' ).replace( /,/g, '' ) ) * unit + ( parseFloat( eduConvFee ) * unit ) ) ) );
        }
    }, [ unit, airtimeAmount, dataAmount, cableAmount, eAmount, eConvFeeee, product,eduAmount,eduConvFee ] )

  

    const generateCouponCode = () => {
        const amt = totalAmount.replace( /₦/g, '' ).replace( /,/g, '' ).replace( / '' /g, '' )
          
        if ( parseInt( amt ) > parseFloat( user.wallet_balance ) ) {
            window.scrollTo( 0, 0 );
            return setMsg( 'Insufficient funds.' )
        }
        if ( !pin ) {
            window.scrollTo( 0, 0 );
            return setMsg( 'Pin Required.' );
        }

        let data;

        //For Airtime
        if ( product === 'Airtime' ) {
            if ( parseInt( amt ) < 200 ) {
                window.scrollTo( 0, 0 );
                return setMsg( 'Minimum amount of ₦200 can be recharged.' )
            }
            const amountAlpha = airtimeAmount.match( /[a-zA-Z]/g );
            const amountSpace = airtimeAmount.match( /\s/g );
            const amountnon_digit = airtimeAmount.match( /\D/g );
            if ( amountAlpha || amountSpace || amountnon_digit ) {
                window.scrollTo( 0, 0 );
                return setMsg( 'Invalid Amount Entered.' )
            }
            data = {
                product:product.toUpperCase(),
                amount: airtimeAmount,
                unit,
                pin
            }

            
        }

        //For Data
        if ( product === 'Data' ) {

            if( selectedNetwork === 'SPECTRANET' || selectedNetwork === 'SMILE BUNDLES'){
                data = {
                    product:'DATA-NON-REGULAR',
                    unit,
                    pin,
                    service_id:serviceId,
                    plan_id:planId
                   
                }
            }else{
                data = {
                    product:'DATA-REGULAR',
                    unit,
                    pin,
                    network_id:networkId,
                    plan_id:planId
                   
                }
            }
            

            

            
        }
        //For CableTV
        if ( product === 'CableTV' ) {
          
            data = {
                product:product.toUpperCase(),
                unit,
                service_id:cableServiceId,
                plan_id:CablePlanId,
                pin,
              
            }
        };

//For Education
        if ( product === 'Education' ) {
          
            data = {
                product:product.toUpperCase(),
                unit,
                service_id:eduServiceId,
                plan_id:eduPlanId,
                pin,
              
            }
        };

        //For Electricity
        if ( product === 'Electricity' ) {
            if ( parseInt( amt ) < 200 ) {
                window.scrollTo( 0, 0 );
                return setMsg( 'Minimum amount of ₦200 can be recharged.' )
            }
            const amountAlpha = eAmount.match( /[a-zA-Z]/g );
            const amountSpace = eAmount.match( /\s/g );
            const amountnon_digit = eAmount.match( /\D/g );
            if ( amountAlpha || amountSpace || amountnon_digit ) {
                window.scrollTo( 0, 0 );
                return setMsg( 'Invalid Amount Entered.' )
            }
            data = {
                product:product.toUpperCase(),
                unit,
                amount: eAmount,
                pin
            }
        }

        //Send To Back-End
        if ( data ) {
            setMsg( '' )
            // return console.log(data)
            store.dispatch( buyCoupon( data ) )
            
        }
    }
  

    return (
        <div className="buy-coupon">
            <h1>Buy Coupon</h1>
            <form>
                <h5>{ msg }</h5>
                <div>
                    <label>Select product</label>
                    <select value={ product } onChange={ ( e ) => setProduct( e.target.value ) }>
                        <option  >select product</option>
                        { availableProducts ? availableProducts.map( ( name ) => (
                            <>
                                
                                <option >{ name }</option>
                            </>
                        ) ) : <option >select product</option> }
                    </select>
                </div>
                { product === 'Airtime' ? returnAirtime() : product === 'Data' ? returnData() : product === 'CableTV' ? returnCable() : product === 'Electricity' ? returnElectricity() :product ==='Education'? returnEducation(): null }
                
                { product && product !== 'select product' ?
                    <>
                        <div className="units">
                            <label>Units</label>
                            <div>
                                <i className="fa fa-minus" onClick={ () => updateUnit( 'minus' ) }></i>
                                <span>{ unit }</span>
                                <i className="fa fa-plus" onClick={ () => updateUnit( 'plus' ) }></i>
                            </div>
                        </div>
                        <div className="total-amount">
                            <label>Total amount{ product === 'CableTV' ? `  + Convenience fee(₦${ convFee })` :null }</label>
                            <input disabled value={ totalAmount } />
                        </div>
                        <div className="pin">
                            <label>Transaction Pin</label>
                            <input value={ pin } onChange={ ( e ) => setPin( e.target.value ) } />
                        </div>
                    </>
                    
                    : null }
            </form>
            { product && product !== 'select product' ? <p onClick={ () => generateCouponCode() } >Generate</p> : null }
        </div>
    );
};

const mapStateToProps =({data:{errorMessage,user},public:{electricity_variations,education_variations,cabletv_variations,data_variations}})=>({
errorMessage,user,electricity_variations,education_variations,cabletv_variations,data_variations
});

export default connect(mapStateToProps, null)(Buy);
