import React from 'react'
import Overview from './Overview/Overview';
import Wallet from './Wallet/Wallet';
import Transactions from './Transactions/Transactions';
import Airtime from './Airtime/Airtime';
import Data from './Data/Data';
import Cabletv from './Cabletv/Cabletv';
import Electricity from './Electricity/Electricity';
import Settings from './Settings/Settings';
import Coupon from './Coupon/Coupon';
import Education from './Education/Education'
import { connect } from 'react-redux';
const MainPage = ( { page, setPageToShow, setTdetails,data } ) => {
    const { transactions, user} = data;
    
    
    
    return (
        <section className="mainpage">
            { ( page === 1 ) ? <Overview Transactions={ transactions } User={ user } />
                : ( page === 2 ) ?
                    <Wallet User={ user } setPageToShow={ setPageToShow } />
                    : ( page === 3 ) ?
                        <Transactions setPageToShow={ setPageToShow }
                            setTdetails={ setTdetails } Transactions={ transactions } />
                        : ( page === 4 ) ? <Airtime />
                            : ( page === 5 ) ?
                                <Data  />
                                : ( page === 6 ) ? <Cabletv  />
                                    : ( page === 7 ) ? <Electricity  />
                                        : ( page === 8 ) ? <Settings   />
                                            : ( page === 10 ) ? <Coupon  />
                                            : ( page === 11 ) ? <Education  />
                                                : null }
        </section>
    );
};

const mapStateToProps = ( { data } ) => ( {
    data
} );

export default connect( mapStateToProps, null )( MainPage );
