import React from 'react'
import './Footer.css'
import './mobile.css'
const Footer = () => {
    return (
       
        <footer id="contact-us" className="contact-us">
        <div className="logo">
        <img className="site-logo" src="../../images/logo-website-home-header.png" alt="CreditSync"/>
        </div>
        <div className="contact">
            <h3>Contact Us</h3>
            <p><i className="fa fa-phone-alt"></i>  (+234) 8147012359</p>
            <p><i className="fa  fa-envelope"></i>  support@creditsync.com.ng</p>
            
        </div>
        <div className="contact">
            <h3>Social Links</h3>
            <div className="social-links">
            <a href="https://twitter.com/CreditSync_" target="_blank"> <i className="fab fa-twitter"></i></a>
            <a href="https://www.facebook.com/creditsync.com.ng" target="_blank"> <i className="fab fa-facebook"></i></a>
            <a href="https://api.whatsapp.com/send?phone=+2349056958763&text=Hello,%20this%20is%20from%20creditsync%20home%20page."> <i className="fab fa-whatsapp"></i></a>
            </div>
           
        </div>
    </footer>
       
    )
}

export default Footer
