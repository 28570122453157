import React, { useEffect } from 'react';
import './faqs.css';
import './mobile.css';
import Footer from '../Footer/Footer';
const Faqs = () => {
  useEffect(() => {
    writeUp();
  }, []);
  const writeUp = () => {
    let timing = '0.5s';
    const q1 = document.querySelector('.question-1');
    const q2 = document.querySelector('.question-2');
    const q3 = document.querySelector('.question-3');
    const q4 = document.querySelector('.question-4');
    const q5 = document.querySelector('.question-5');
    const q6 = document.querySelector('.question-6');
    const q7 = document.querySelector('.question-7');
    const q8 = document.querySelector('.question-8');

    let observeQ1 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-left ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ1.observe(q1);

    let observeQ2 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-right ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ2.observe(q2);

    let observeQ3 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-left ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ3.observe(q3);

    // let observeQ4 = new IntersectionObserver((entries) => {
    //   if (entries[0].intersectionRatio > 0) {
    //     entries[0].target.style.animation = `animate-from-right ${timing} forwards ease-out`;
    //   } else {
    //     entries[0].target.style.animation = 'none';
    //   }
    // });
    // observeQ4.observe(q4);

    // let observeQ5 = new IntersectionObserver((entries) => {
    //   if (entries[0].intersectionRatio > 0) {
    //     entries[0].target.style.animation = `animate-from-left ${timing} forwards ease-out`;
    //   } else {
    //     entries[0].target.style.animation = 'none';
    //   }
    // });
    // observeQ5.observe(q5);

    let observeQ6 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-right ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ6.observe(q6);

    let observeQ7 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-left ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ7.observe(q7);

    let observeQ8 = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-from-right ${timing} forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeQ8.observe(q8);
  };
  return (
    <section className='faqs'>
      <div className='image'></div>
      <div className='image-overlay'>
        <h1>FaQs</h1>
      </div>
      <div className='write-up'>
        <div className='question-1'>
          <h1>How do i get started with the platform ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>Visit{' '}
            <a
              href='https://play.google.com/store/apps/details?id=com.creditsync'
              target='_blank'
            >
              here
            </a>{' '}
            and get registered.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Note down the 6-digit secret
            pin generated while registering.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>A verification link would be
            sent to your email, check your spam folder if not found.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>After your email has been
            verified, visit the login page to login using your credentials.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Fund your wallet via ATM card
            payment. Note: Flutterwave deducts 1.4% service charge so it is
            advisable you fund your wallet with a reasonable amount.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>You can go ahead to buy any of
            our product using your funded wallet.
          </p>
        </div>
        <div className='question-2'>
          <h1>How do i fund my wallet ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>Click on the wallet icon on the
            side-bar menu on your dashboard.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>A page showing your available
            balance,share wallet and fund wallet features would load up.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Click on the fund wallet button
            and enter the amount you wish to pay, then click pay.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>If all is right and good a
            payment page requesting for your payment credentials will load up.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Once your payment is
            successful, your wallet will be credited automatically.
          </p>
        </div>
        <div className='question-3'>
          <h1>My email verification link has expired, what can i do ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>Contact our support team.
          </p>
        </div>
        {/* <div className='question-4'>
          <h1>Do you have a referral program ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>Yes we do.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Your referral link can be found
            on the settings page of your dashboard under the profile section.
          </p>
        </div> */}
        {/* <div className='question-5'>
          <h1>What are my benefits on the referral program ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>For every person you refer, you
            get an 0.1% of their first 10 transactions(except buying of coupons)
            which is capped at ₦100.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>The more you refer the more you
            earn.
          </p>
        </div> */}
        <div className='question-6'>
          <h1>What is the coupon feature about ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>With the coupon feature, you
            can buy our product in bulk and gift to friends in form of coupons.
          </p>
        </div>
        <div className='question-7'>
          <h1>How does the coupon feature work ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>Select the product you wish to
            purchase.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>By Clicking on the minus(-) and
            plus(+) sign you can increase or decrease the numbers of units you
            wish to purchase.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Enter your 6-digit secret pin,
            then click on the generate button.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>If the codes were generated
            successfully, the codes will appear on your right-hand side(on
            desktop) or below the buy coupon form(on mobile).
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Copy the code and give to
            recipient.
          </p>
        </div>
        <div className='question-8'>
          <h1>How can i redeem my coupon code ?</h1>
          <p>
            <i className='fa fa-info-circle'></i>The code is always in this
            format (JOH-AFE5CA9CC2-CABLETV)the last part states the product
            which the coupon is meant for.
          </p>
          <p>
            <i className='fa fa-info-circle'></i>Go to the stated product
            section and choose the use coupon option, paste the coupon code with
            no spaces and click on the load button.
          </p>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Faqs;
