import React,{useEffect,useState}  from 'react'
import './Last5Transactions.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions';
// import Moment from 'react-moment'
const Last5Transactions = ({Transactions}) => {
    const [ sorted, setSorted ] = useState( [] );
    

    // const transactions = Transactions.slice(0,5)
    

    useEffect( () => {
        if ( Transactions && Transactions.length !== 0 ) {
            const compare =(a, b) =>{
                
                //This will only Be in Descending order
                const A = a.date;
                const B = b.date;
             
                let comparison = 0;
                //To Print in ascending order change this to >
                if (A < B) {
                    comparison = 1;
                    
                } else{
                  comparison = -1;
                }
                
                return comparison;
            }
            setSorted(Transactions.sort(compare).slice(0,5))
            
           }
       },[Transactions])

    const setStatusBackground = (transaction) => {
        if ( transaction.status === 'failed' ) {
            return { background: "#f85d5d " };
        } else if (transaction.status === 'reversed') {
            return {background:"#4a7111"}
        }else {
            return { background: "#5df888" };
    }
}

    
    const formatDate = (date) => {
        let month = new Date( parseInt(date) ).getMonth() +1;
        let day = new Date( parseInt(date) ).getDate();
        const year = new Date( parseInt( date ) ).getFullYear();
        
        if ( day < 10 ) {
            day = '0' + day
        }

        if ( month < 10 ) {
            month = '0' + month
        }

        return `${day} - ${month} - ${year}`
    }


    const tableData = (transaction) => {
        return (
             <div key={transaction.t_id} className="table-data">
                     <ul>
                        <li>{formatDate(transaction.date)}</li>
                        <li className="hide-on-mobile">{transaction.t_id}</li>
                        <li> {transaction.product}</li>
                        <li>{Utils.formatCurrency(parseInt(transaction.value))}</li>
                        <li style={setStatusBackground(transaction)} className="status">{transaction.status}</li>
                    </ul>
            </div>
        )
    }





    return (
        <div className="last5transactions">
            <h3>Transactions</h3>
            <div className="table">
                <div className="table-label">
                    <ul>
                        <li>Date</li>
                        <li className="hide-on-mobile">Trasaction Id</li>
                        <li>Product</li>
                        <li>Value</li>
                        <li>Status</li>
                    </ul>
                </div>
                { ( sorted ) ?
                    sorted.map( transaction => (
                        tableData(transaction)
                    ))
                 : null }
            </div>
        </div>
    )
}

export default Last5Transactions
