import React,{useState,useEffect} from 'react'
import './Cable.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
import store from '../../../../store'
import {userLoad,editProduct} from '../../../../actions/Action'
const Cable = ( { cable } ) => {
    const [ edit, setEdit ] = useState( false );
    const [name, setName] = useState(cable.data.name)
    const [ price, setPrice ] = useState( cable.data.price );
    useEffect( () => {
        setName( cable.data.name );
        setPrice( cable.data.price )
        return ( () => {
            setName( '' );
            setPrice( '' );
            setEdit(false)
        } )
    }, [ cable ] );

    const update = () => {
        let data = {
            plan: name,
            name: cable.selectedTV,
            plan_id: cable.data.id,
            price,
        };
        Utils.closeAdminPopUp()
        store.dispatch(userLoad())
        store.dispatch( editProduct( data ) );
    }


    return (
        <div className="cable-details slowly-show-popups">
        <div>
        <form>
            <div>
                <label>Name</label>
                <input value={name} onChange={(e)=>setName(e.target.value)} disabled={!edit} />
            </div>
            <div>
                <label>Price</label>
                <input value={price} onChange={(e)=>setPrice(e.target.value)} disabled={!edit} />
            </div>
        </form>
        <div className="options">
            <p>Delete</p>
            <p onClick={()=>Utils.closeAdminPopUp()}>Cancel</p>
            {(edit)? <p onClick={()=>update()}>Update</p>: <p onClick={()=>setEdit(true)}>Edit</p>}
        </div>
    </div>
        </div>
    )
}

export default Cable
