import React from 'react'
import './Wallet.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions'
const Wallet = ({User}) => {
    return (
        <div className="wallet-balance">
            <div className="value">
                <p><h5>NGN</h5> {User && User.wallet_balance?Utils.currency(parseFloat(User.wallet_balance)) : null}</p>
            </div>
            <p>Wallet Balance</p>
        </div>
    )
}

export default Wallet
