import React from 'react'
import './Wallet.css';
import Personal from './PersonalWallet/PersonalWallet';
import SharedWallet from './ShareWallet/SharedWallet'
const Wallet = ({User,setPageToShow}) => {
    return (
        <section className="wallet">
            <h2>Wallet</h2>
            <div className="wrapper">
                <Personal User={User} setPageToShow={setPageToShow} />
                <SharedWallet User={ User}/>
            </div>
        </section>
    )
}

export default Wallet
