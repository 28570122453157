import React, {useState,useEffect} from 'react'
import './Data.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
import store from '../../../../store';
import {buyData,userLoad,validateCoupon} from '../../../../actions/Action';
import { connect } from 'react-redux';
import { HandleVerifyCoupon } from '../../../../actions/public';
import { VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS } from '../../../../actions/public/types';
const Data = ( {  errorMessage,user,server_message,data_variations } ) => {
    const [ checked, setChecked ] = useState( false );
    const [ pin, setPin ] = useState( '' );
    const [ amount, setAmount ] = useState();
    const [ plans, setPlans ] = useState();
    const [ planId, setplanId ] = useState( '' );
    const [ plan, setPlan ] = useState( '' )
    const [ phoneNumber, setPhoneNumber ] = useState( '' );
    const [ msg, setMsg ] = useState( '' );
    const [allProviders, setAllProviders] =useState([])
    const [ dataSubriptions, setDataSubcriptions ] = useState( [] )
    const [ selectedNetwork, setSelectedNetwork ] = useState();
    const [ networkId, setNetworkId ] = useState( '' );
    const [ serviceId, setServiceId ] = useState( '' );
    const [ accountId, setAccountId ] = useState( '' );
    const [ showAccountIdField, setShowAccountIdField ] = useState( false )
    const [ code, setCode ] = useState( '' );

    //Check Error Message
    useEffect( () => {
        if ( errorMessage && errorMessage.type === 'purchase_data_fail' ) {
            setMsg( errorMessage.msg )
            // Utils.closePopUp();
        } else if ( errorMessage && errorMessage.type === 'purchase_data_success' ) {
            setMsg( errorMessage.msg );
            setPin( '' );
            setChecked( false )
            setAccountId('')
            setPhoneNumber( '' )
            // Utils.closePopUp();
        } 
        
        if ( server_message.type === VERIFY_COUPON_FAIL ) {
            setMsg(server_message.info )
        } 
        
        if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'DATA-REGULAR' || server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'DATA-NON-REGULAR' ) {
            

            setplanId( server_message.info.meta_data.plan_id )
            setPlan( server_message.info.meta_data.plan )
            setNetworkId( server_message.info.meta_data.network_id )
            setAmount( Utils.formatCurrency( parseInt( server_message.info.meta_data.amount ) ) );
            setServiceId( server_message.info.meta_data.service_id ? server_message.info.meta_data.service_id : undefined )
            setSelectedNetwork( server_message.info.meta_data.name )
        } 
        
        if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'DATA-REGULAR' && server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'DATA-NON-REGULAR' ) {
            setMsg( 'Invalid coupon code supplied.' )
        }
        // setMsg('')
    }, [ errorMessage,server_message ] );

    useEffect( () => {
        
        setAllProviders(data_variations);
        return ()=>console.log('');

    }, [ data_variations ] );


    useEffect( () => {
        if ( selectedNetwork === 'SPECTRANET' || selectedNetwork === 'SMILE BUNDLES' ) {
            setShowAccountIdField( true )
        } else {
            setShowAccountIdField( false )
        };

        return ()=>console.log('');
    }, [ selectedNetwork ] )

    useEffect( () => {
        
        setDataSubcriptions( data_variations );
        
    }, [ data_variations ] )

    useEffect( () => {
        if ( dataSubriptions.length !== 0 ) {
            setSelectedNetwork( dataSubriptions[ 0 ].name );
            setNetworkId( dataSubriptions[ 0 ].network_id );
            setServiceId( dataSubriptions[ 0 ].service_id );
        }
    }, [ dataSubriptions ] )



    //Update Data Plans to Show
    useEffect( () => {
        if ( selectedNetwork ) {
            const dataPlans = dataSubriptions.filter( d => d.name === selectedNetwork );
            if ( dataPlans.length !== 0 ) {
                
                setPlans( dataPlans[ 0 ].plans );
                setNetworkId( dataPlans[ 0 ].network_id );
                setServiceId( dataPlans[ 0 ].service_id );

            } else {
                setPlans()
            }
            
        }
    }, [ selectedNetwork ] )



    const updateAmount = ( e ) => {
        const value = e.target.value;
       
        
       
        let amount = plans.filter( plan => plan.id === e.currentTarget.selectedOptions[ 0 ].id );
        if ( amount && amount.length !== 0 ) {
            setAmount( Utils.formatCurrency( parseInt( amount[ 0 ].wallet_price ) ) );
            setplanId( amount[ 0 ].plan_id )
        }
        
        
    }

    useEffect( () => {
        if ( plans && plans.length !== 0 ) {
            setAmount( Utils.formatCurrency( parseInt( plans[ 0 ].wallet_price ) ) );
            setplanId( plans[ 0 ].plan_id )
        } else {
            setAmount( '' );
            setplanId( '' )
        }
    }, [ plans ] );

    const updateNetwork = ( e ) => {
    
    }
    const orderData = () => {
        let firstThree = phoneNumber.slice( 0, 3 );

        const numberArray = [ '070', '081', '080', '090','091' ]
        const indexOfFirstThreeNumber = numberArray.indexOf( firstThree );
        const lenthOfPhoneNumber = phoneNumber.length === 11;
        const space = phoneNumber.match( /\s/g );
        const non_digit = phoneNumber.match( /\D/g );
        const aplha = phoneNumber.match( /[a-zA-Z]/g );
        if ( Math.round( amount.replace( /₦/g, '' ).replace( /,/g, '' ) ) > parseFloat( user.wallet_balance ) ) {
            return setMsg( 'Insufficient funds.' )
        } else if ( !showAccountIdField ) {
            if ( phoneNumber && planId !== '' && indexOfFirstThreeNumber !== -1 && !space && !non_digit && !aplha && lenthOfPhoneNumber
               
                // && checked
            ) {
           
                if ( pin ) {
                    setMsg( '' );
                    
                    const data = {
                        phone_number:phoneNumber,
                        pin,
                        plan_id:planId,
                        network_id:networkId,
                        service_id:serviceId,
                        account_id:accountId,
                        coupon_code: code
                    }
                    setMsg( '' )

                    store.dispatch( buyData( data ) )
                } else {
                    setMsg( 'Pin Required' )
                }
               
            }
            else {
                setMsg( 'Invalid Phone Number' )
            }
        } else {
            if ( accountId && planId !== ''
            ) {
                setMsg( '' );
    
                const data = {
                        
                    pin,
                    plan_id:planId,
                        
                    service_id:serviceId,
                    account_id:accountId,
                    coupon_code: code
                }
                setMsg( '' )
                store.dispatch( buyData( data ) )
            } else {
                setMsg( 'Invalid account id.' )
            }
            
        }
    }
     
    const modalLeft = () => {
        return (
            <div className="modal-left">
                <form>
                    <h5>{ msg }</h5>
                    <div>
                        <label>Select Network</label>
                        <select value={ selectedNetwork } onChange={ ( e ) => setSelectedNetwork( e.target.value ) }>
                            { ( allProviders.length > 0 ) ? allProviders.map( ( provider ) => (
                                <option key={ provider.id }>{ provider.name }</option>
                            ) ) : <option>Select Network</option> }
                                
                        </select>
                    </div>
                    <div>
                        <label>Select Plan</label>
                        <select onChange={ ( e ) => updateAmount( e ) }  >
                       
                            { ( plans ) ? plans.map( p => (
                                <option id={ p.id } plan_id={ p.plan_id } key={ p.id }>{ p.name.toUpperCase() }</option>
                            ) ) : <option>Select Plan</option> }
                        </select>
                    </div>
                    <div>
                        <label>Amount</label>
                        <input disabled={ true } value={ amount } />
                    </div>
                    { showAccountIdField ?
                        <div>
                            <label>Enter Account/UserId</label>
                            <input onChange={ ( e ) => setAccountId( e.target.value ) } value={ accountId } />
                        </div> :
                        <div>
                            <label>Enter Phone Number</label>
                            <input onChange={ ( e ) => setPhoneNumber( e.target.value ) } value={ phoneNumber } />
                        </div> }
                    
                    <div className="pin">
                        <label>Transaction Pin</label>
                        <input onChange={ ( e ) => setPin( e.target.value ) } value={ pin } />
                    </div>

                    {/* <div className="check">
                        <p onClick={(e)=>updateCheck(e)}><i className="fa fa-check checkbox-data"></i></p>
                        <label>I have confirmed the {showAccountIdField?'account id':'phone number'}.</label>
                        
                    </div> */}
                </form>

                <p onClick={ ( e ) => orderData( e ) } >Order Now</p>
            </div>
        )
    }


    const loadCoupon = () => {
        if ( !code ) {
            return setMsg( 'Coupon code cannot be blank.' )
        }
        let data = {
            coupon_code: code
        }
        setMsg( '' )
        
        store.dispatch( HandleVerifyCoupon( data ) )

    }

    const modalRight = () => {
        return (
            <div className="modal-right">
                { plan ?
                    <>
                        <form>
                            <h5>{ msg }</h5>
                            <div>
                                <label>Network</label>
                                <input disabled={ true } value={ selectedNetwork } />
                            </div>
                            <div>
                                <label>Plan</label>
                                <input disabled={ true } value={ plan } />
                            </div>
                            <div>
                                <label>Amount</label>
                                <input disabled={ true } value={ amount } />
                            </div>
                            { showAccountIdField ?
                                <div>
                                    <label>Enter Account/UserId</label>
                                    <input onChange={ ( e ) => setAccountId( e.target.value ) } value={ accountId } />
                                </div> :
                                <div>
                                    <label>Enter Phone Number</label>
                                    <input onChange={ ( e ) => setPhoneNumber( e.target.value ) } value={ phoneNumber } />
                                </div> }

                            <div className="pin">
                                <label>Transaction Pin</label>
                                <input onChange={ ( e ) => setPin( e.target.value ) } value={ pin } />
                            </div>

                            {/* <div className="check">
                        <p onClick={(e)=>updateCheck(e)}><i className="fa fa-check checkbox-data"></i></p>
                        <label>I have confirmed the {showAccountIdField?'account id':'phone number'}.</label>
                        
                    </div> */}
                        </form>
                        <p onClick={ ( e ) => orderData( e ) } >Order Now</p>
                    </> :
                    <>
                        <form>
                            <h5>{ msg }</h5>
                            <div>
                                <div className="coupon-code">
                                    <label>Enter coupon code</label>
                                    <input onChange={ ( e ) => setCode( e.target.value ) } value={ code } />
                                </div>
                            </div>
                        </form>
                        <p onClick={ () => loadCoupon() } >Load</p>
                    </> }


                
            </div>
        )
    }

    const handleChangeForm = ( { e, className } ) => {
        const modalRight = document.getElementsByClassName( 'modal-right' )
        const modalLeft = document.getElementsByClassName( 'modal-left' )
        setPlan( '' )
        setMsg( '' )
        if ( className === 'right' ) {
            //Remove Active class from left
            e.currentTarget.previousElementSibling.classList.remove( 'left-active' )
            e.currentTarget.classList.add( 'right-active' );
            modalLeft[ 0 ].style.display = 'none';
            modalRight[ 0 ].style.display = 'flex'
        }


        if ( className === 'left' ) {
            //Remove Active class from right
            e.currentTarget.nextElementSibling.classList.remove( 'right-active' )
            e.currentTarget.classList.add( 'left-active' );
            modalLeft[ 0 ].style.display = 'flex';
            modalRight[ 0 ].style.display = 'none'
        }
    }

    return (
        <section className="data">
            <h3>Data</h3>
            <div className="wrap">
                <div className="header">
                    <h4 onClick={ ( e ) => { handleChangeForm( { e, className: 'left' } ) } } className="left left-active">Use wallet</h4>
                    <h4 onClick={ ( e ) => { handleChangeForm( { e, className: 'right' } ) } } className="right">Use Coupon</h4>
                </div>
                <div className="form">
                    { modalLeft() }
                    { modalRight() }
                </div>
            </div>
        </section>
    );
};


const mapStateToProps =({data:{errorMessage,user},public:{networks,server_message,data_variations}})=>({
    errorMessage,
    user,
    networks,
    server_message,
    data_variations
})

export default connect(mapStateToProps, null)(Data);
