import React,{useState,useEffect} from 'react'
import './User.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
import store from '../../../../store';
import {blockUser,userLoad,UnblockUser} from '../../../../actions/Action'
const User = ({userDetails,errorMessage}) => {
const [msg,setMsg] = useState('')

    const formatDate = (date) => {
        let hours = new Date( parseInt(date) ).getHours() % 12;//To Get Local hours
        const minutes = new Date( parseInt(date) ).getMinutes();
        let q = 'PM'

        const months = [ 'January', 'February', 'March', 'April', 'May',
            'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        
        const days =['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        let Nday = new Date( parseInt(date) ).getDate();
        let day = days[ new Date( parseInt( date ) ).getDay()];
        let month = months[new Date( parseInt( date ) ).getMonth()];
        let year = new Date( parseInt( date ) ).getFullYear();

        if ( new Date( ( parseInt( date ) ) ).getHours() < 12 ) {
             q = 'AM'
        }
        if ( Nday < 10 ) {
            Nday = '0' + Nday
        }
        if ( hours === 0 ) {
            hours = 12;
        }
        let newDate = new Date(parseInt( date ) )
        const d = {
            time: `${ new Date(parseInt( date ) ).toLocaleString()}`,
               
            date: ` ${ day }  ${Nday} ${ month }, ${ year }`,
         
            newDate
        }

        return d
    };
    

    //Set Error Message 
    useEffect( () => {
        if ( errorMessage && errorMessage.type === 'block_fail' ) {
            setMsg( errorMessage.msg )
        }

        if ( errorMessage && errorMessage.type === 'block_success' ) {
            setMsg(errorMessage.msg)
        }

        return () => console.log('')
    },[errorMessage])

    const Block = (  ) => {
        let data = {
            id: userDetails._id
        }
        store.dispatch(blockUser(data))
       
    }

    const Unblock = (  ) => {
        
            let data = {
                id: userDetails._id
            }
            store.dispatch(UnblockUser(data))
        
    }
    return (
        <div className="userdetails slowly-show-popups">
            <div className="right">
                <h4>{ msg}</h4>
               { userDetails.last_login? <p>Last Login Time: {  formatDate(userDetails.last_login).time }</p> : ''}
               { userDetails.last_login ? <p>Last Login Date:{ formatDate(userDetails.last_login).date} </p>: ''}
                <p>Blocked: {(userDetails.isBlocked)?'True': 'False'}</p>
                <p>Phone: {userDetails.phone}</p>
                <p>Account Created On: {formatDate(userDetails.created_on).date}</p>
            </div>
            
            <div className="left">
                <p onClick={ () => Utils.closeAdminPopUp() }>Close</p>
                { ( userDetails.isBlocked ) ?
                <p onClick={()=>Unblock(userDetails.id)}>UnBlock</p>
                    :
                <p onClick={()=>Block(userDetails.id)}>Block</p>

                }
            </div>
        </div>
    )
}

export default User
