import React,{useState,useEffect} from 'react'
import './Datadetails.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
import store from '../../../../store'
import {userLoad,editProduct} from '../../../../actions/Action'
const Datadetails = ( { dataDetails } ) => {
    
    const [ edit, setEdit ] = useState( false );
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    

    useEffect( () => {
        setName( dataDetails.data.name );
        setPrice( dataDetails.data.wallet_price )
        return ( () => {
            setName( '' );
            setPrice( '' );
            setEdit(false)
        } )
    }, [ dataDetails ] );


    const update = () => {
        let data = {
            plan: name,
            name: dataDetails.selectedNetwork,
            plan_id: dataDetails.data.plan_id,
            price,
            
        };
        Utils.closeAdminPopUp()
        store.dispatch(userLoad())
        store.dispatch( editProduct( data ) );
        
    }
    return (
        <div className="data-details slowly-show-popups">
            <div>
                <form>
                    <div>
                        <label>Name</label>
                        <input value={name} onChange={(e)=>setName(e.target.value)} disabled={!edit} />
                    </div>
                    <div>
                        <label>Price</label>
                        <input value={price} onChange={(e)=>setPrice(e.target.value)} disabled={!edit} />
                    </div>
                </form>
                <div className="options">
                    <p>Delete</p>
                    <p onClick={()=>Utils.closeAdminPopUp()}>Cancel</p>
                    {(edit)? <p onClick={()=>update()}>Update</p>: <p onClick={()=>setEdit(true)}>Edit</p>}
                </div>
            </div>
        </div>
    )
}

export default Datadetails
