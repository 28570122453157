import React,{useState,useEffect} from 'react'
import './style.css'
import './mobile.css'
import Utils from '../../../../Utils/Functions'
import { connect } from 'react-redux'
const Available = ({user}) => {
    const [ filtered, setFiltered ] = useState( [] );
    const [ currentItems, setCurrentItems ] = useState( [] );
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const [ itemsPerPage ] = useState( 5 );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalItems = filtered.length;
    const pages = Math.ceil( totalItems / itemsPerPage );

    useEffect( () => {
        if ( user ) {
            const compare =(a, b) =>{
                
                //This will only Be in Descending order
                const A = a.issued_at;
                const B = b.issued_at;
             
                let comparison = 0;
                //To Print in ascending order change this to >
                if (A < B) {
                    comparison = 1;
                    
                } else{
                  comparison = -1;
                }
                
                return comparison;
            }
            setFiltered(user.coupons.sort(compare))
            
           }
        // setFiltered( User? User.coupons:[] )
    }, [ user] );

    useEffect( () => {
        const items = filtered.slice(indexOfFirstItem,indexOfLastItem)
    setCurrentItems(items)
},[filtered,currentPage])

   // handle  pagination
   const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    };


    const emptyState = () => {
        return (
            <React.Fragment>
                <h3>No Coupons Available.</h3>
            </React.Fragment>
        )
    }

    const availableCoupons = (coupon) => {
        return (
            <div className="coupon">
                <p>Coupon-Code: {coupon.coupon_code }</p>
                <p>Product: { coupon.meta_data.product.toUpperCase() }</p>
                { coupon.meta_data.name ? <p>Name: { coupon.meta_data.name}</p> : null}
                { coupon.meta_data.plan ? <p>Plan: { coupon.meta_data.plan }</p> : null }
                <p>Amount: { Utils.formatCurrency( parseInt( coupon.meta_data.amount ) ) }</p>
                <p>Active: { coupon.isValid ? 'YES' : 'NO' }</p>
                <p>Valid Till: {new Date(parseInt(coupon.expires_at)).toLocaleString() }</p>
                
            </div>
        )
    }

    const pagination = () => {
        return (
            <div className="pagination-box">
                <div className="">
                    {currentPage > 1 && (
                        <i className="fa fa-chevron-left"
                        onClick={() => handlePagination(currentPage - 1)}
                        disabled={currentPage === 1 ? true : false}></i>
                    
                    )}
                </div>
                <div className="">
                    <p className="btn-active">{currentPage}</p>
                    <span className="pages"> of {pages}</span>
                </div>
                <div className="">
                    {currentPage < pages && (
                    <i className="fa fa-chevron-right"
                        onClick={() => handlePagination(currentPage + 1)}
                        
                        disabled={currentPage === pages ? true : false}
                    >
                        
                    </i>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="available-coupon">
            <h1>Coupons</h1>
            { currentItems && currentItems.length !== 0 ?
                <>
                    { currentItems.map( coupon => (
                    availableCoupons(coupon)
                    ) ) }
                    {pagination()}
               </>
             : emptyState() }
        </div>
    )
};

const mapStateToProps =({data:{user}})=>({
user
})

export default connect(mapStateToProps, null)(Available)
