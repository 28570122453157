import React,{useState,useEffect} from 'react'
import './TotalTransactions.css'
import Utils from '../../../../../Utils/Functions'
const TotalTransactions = ({Payments}) => {

    let [price, setPrice] = useState(0)
    
   

    const getPrices = () => {

        price =0
    Payments.map( t => {
            
            // if ( t.status === 'successful' ) {
                price += parseFloat(t.amount);
            setPrice(price)
            // }
        })
    }

    useEffect( () => {
       
            getPrices(); 
        

        

    },[Payments])
    return (
        <div className="total-transactions-admin">
            <div className="value">
                
                <p><h5>NGN</h5>{ Utils.currency(parseInt(price))}</p>
            </div>
            <p>Total Payments</p>
        </div>
    )
}

export default TotalTransactions
