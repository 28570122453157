import React from 'react'
import './TotalNumberOfTransactions.css'
const TotalNumberOfTransactions = ({Payments}) => {
    return (
        <div className="total-number-of-transactions-admin">
        <div className="value">
                <p>{Payments?Payments.length : null}</p>
    </div>
    <p>Total Number <br></br>Of Payments</p>
        </div>
    )
}

export default TotalNumberOfTransactions
