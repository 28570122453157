import React,{useEffect,useState} from 'react'
import './Transactions.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions'
const Transactions = ({setPageToShow,setTdetails,Transactions}) => {
    const [ filtered, setFiltered ] = useState( [] );
    const [currentItems,setCurrentItems] =useState([])
    const [searchByUser,setSearchByUser] =useState('');
    const [ searchById, setSearchById ] = useState( '' );
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const [ itemsPerPage ] = useState( 5 );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalItems = filtered.length;
    const pages = Math.ceil( totalItems / itemsPerPage );
  
    useEffect( () => {
        setFiltered( Transactions )
    }, [ Transactions ] );

    useEffect( () => {
        const items = filtered.slice(indexOfFirstItem,indexOfLastItem)
    setCurrentItems(items)
},[filtered,currentPage])
    const setStatusBackground = ( transaction ) => {
        if ( transaction.status === 'failed' ) {
            return { background: "#f85d5d " };
        } else if ( transaction.status === 'reversed' ) {
            return { background: "#4a7111" }
        }else if (transaction.status === 'pending') {
            return {
                background: '#707471'
            }
        } else if (transaction.status === 'cancelled') {
            return {
                background: '#f85d5d'
            }
        }
        else {
            return { background: "#5df888" };
        }
    };

    // handle  pagination
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    };
    

    


    
    useEffect( () => {
        if(!searchById){
            if ( searchByUser ) {
                let fill = Transactions.filter( transaction => transaction.user === searchByUser );
            setFiltered(fill )
            } else {
                setFiltered( Transactions );
            }
        }
    },[searchByUser])

    useEffect( () => {
        if ( !searchByUser ) {
            if ( searchById) {
                let fill = Transactions.filter( transaction => transaction.transaction_id === searchById );
            setFiltered(fill )
            } else {
                setFiltered( Transactions );
            }
       }
    },[searchById])



    const Open = (data) => {
        setPageToShow( 'transactiondetails' );
        setTdetails(data)
        Utils.showAdminPopUp();
    }



    const tableData = (transaction) => {
        return (
             <div key={transaction.transaction_id} className="table-data" onClick={()=>Open(transaction)}>
                     <ul>
                     <li className="hide-on-mobile">{transaction.transaction_id}</li>
                        <li className="hide-on-mobile">{transaction.user}</li>
                        
                        <li> {transaction.product}</li>
                        <li>{Utils.formatCurrency(parseInt(transaction.value))}</li>
                        <li style={setStatusBackground(transaction)} className="status">{transaction.status}</li>
                    </ul>
            </div>
        )
    }

    const pagination = () => {
        return (
            <div className="pagination-box">
                <div className="">
                    {currentPage > 1 && (
                        <i className="fa fa-chevron-left"
                        onClick={() => handlePagination(currentPage - 1)}
                        disabled={currentPage === 1 ? true : false}></i>
                    
                    )}
                </div>
                <div className="">
                    <p className="btn-active">{currentPage}</p>
                    <span className="pages"> of {pages}</span>
                </div>
                <div className="">
                    {currentPage < pages && (
                    <i className="fa fa-chevron-right"
                        onClick={() => handlePagination(currentPage + 1)}
                        
                        disabled={currentPage === pages ? true : false}
                    >
                        
                    </i>
                    )}
                </div>
            </div>
        )
    }

    return (
        <section className="admin-transactions-page">
            <div className="header">
                <h3>Transactions</h3>
                <div>
                <input onChange={(e)=>setSearchByUser(e.target.value)}  type="text" placeholder="search by user"/>
                <input onChange={(e)=>setSearchById(e.target.value)}  type="text" placeholder="search by id"/>
                </div>
            
            </div>
        <div className="table">
            <div className="table-label">
                <ul>
                <li className="hide-on-mobile">Transaction ID</li>
                    <li className="hide-on-mobile">User</li>
                    
                    <li>Product</li>
                    <li>Value</li>
                    <li>Status</li>
                </ul>
            </div>
            { (currentItems ) ?
                currentItems.map( transaction => (
                    tableData(transaction)
                ))
             : null }
             {pagination()}
        </div>
            
            
        </section>
    )
}

export default Transactions
