import React from 'react'
import './Header.css'
import './mobile.css'


import {connect} from 'react-redux'
const Header = ({user}) => {

    const handleSideBar = () => {
        const description = document.getElementsByClassName( 'description' );
        const sidebar = document.getElementsByClassName( 'sidebar' );
        if ( sidebar[ 0 ].classList.contains( 'show-sidebar' ) ) {
            sidebar[0].classList.remove('show-sidebar');
            for ( let i = 0; i < description.length; i++ ){
                description[i].classList.remove('slowly-show-description')
            

            }
        } else {
            sidebar[0].classList.add('show-sidebar')
            for ( let i = 0; i < description.length; i++ ){
            description[i].classList.add('slowly-show-description')
            
        }
        }
        
       
    }

    return (
        <header className="dashboard-header">
            <div className="left">
                <img className="site-logo" src="../../images/logo-website.png" alt="CreditSync" />
            </div>
            <div className="header-right">
                
                <p className="username">{user && user.name?user.name.toUpperCase():null}</p>
                
                <i onClick={()=>handleSideBar()} className="fa fa-bars"></i>
            </div>
        </header>
    )
};


const mapStateToProps = ( { data } ) => ( {
    user: data.user
} );

export default connect(mapStateToProps, null)(Header)
