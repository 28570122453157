import React from 'react'
import Overview from './Overview/Overview';
import Users from './Users/Users';
import Transaction from './Transactions/Transactions';
import Payment from './Payments/Payments';
import Data from './Data/Data';
import CableTV from './CableTV/CableTV'
const Mainpage = ({page,users,products,Balance, setPageToShow,setCable,setUserDetails,setDataDetails, setTdetails,setPaymentDetails,Transactions,payments}) => {
    return (
        <section className="admin-main-page ">
            { ( page === 1 ) ? <Overview Balance={Balance} Users={users} Payments={payments} Transactions={Transactions} setPageToShow={setPageToShow} setUserDetails={ setUserDetails}/> 
            :(page === 2)? <Users Users={users} setPageToShow={setPageToShow} setUserDetails={ setUserDetails}/>
            : ( page === 3 ) ? <Transaction Transactions={Transactions} setPageToShow={ setPageToShow } setTdetails={ setTdetails}/>
            : ( page === 4 ) ? <Payment Payments={payments} setPaymentDetails={setPaymentDetails} setPageToShow={ setPageToShow}/>
            :(page === 5)?<Data Products={products} setPageToShow={ setPageToShow} setDataDetails={setDataDetails}/>
            :(page === 6)?<CableTV Products={products} setCable={setCable} setPageToShow={setPageToShow}/>
            : null}
        </section>
    )
}

export default Mainpage
