import React, { useEffect, useState } from 'react';
import store from '../../../store';
import { HandleVerifyEmail } from '../../../actions/Action';
import './verify.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { VERIFICATION_FAIL, VERIFICATION_PASS } from '../../../actions/types';
const Verify = ({ match, errorMessage }) => {
  const [msg, setMsg] = useState('');
  const history = useHistory();
  useEffect(() => {
    if (match.params) {
      const { token } = match.params;
      if (token) {
        const data = {
          token,
        };
        store.dispatch(HandleVerifyEmail(data));
      } else {
        // history.push('/auth/signup');
      }
    }
    return;
  }, [window.location.search]);

  useEffect(() => {
    if (errorMessage && errorMessage.type === VERIFICATION_FAIL) {
      setMsg(errorMessage.msg);
    } else if (errorMessage && errorMessage.type === VERIFICATION_PASS) {
      setMsg('Your Email has been successfully verified.');
    }

    return () => '';
  }, [errorMessage]);
  return (
    <section className='verify-email'>
      <div>{msg ? <h3>{msg}</h3> : <h3>Verifying Email...</h3>}</div>
    </section>
  );
};

const mapStateToProps = ({ data: { errorMessage } }) => ({
  errorMessage,
});

export default connect(mapStateToProps, null)(Verify);
