import React,{useState,useEffect} from 'react'
import './Payments.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions'
const Payments = ({setPageToShow,setPaymentDetails,Payments}) => {
    const [ filtered, setFiltered ] = useState( [] );
    const [searchByUser,setSearchByUser] =useState('');
    const [ searchByRef, setSearchByRef ] = useState( '' );

    
    const setStatusBackground = (payment) => {
        if (payment.status === 'completed') {
            return {
                background: "#5df888"
            };
        } else if (payment.status === 'failed') {
            return {
                background: "#f85d5d"
            };
        } else if (payment.status === 'pending') {
            return {
                background: "#707471"
            }
        } else if (payment.status === 'cancelled') {
            return {
                background: "#f85d5d"            }
        }
    }

    
useEffect( () => {
    setFiltered( Payments )
}, [ Payments ] );

useEffect( () => {
    if(!searchByRef){
        if ( searchByUser ) {
            let fill = Payments.filter( payment => payment.user === searchByUser );
        setFiltered(fill )
        } else {
            setFiltered( Payments );
        }
    }
},[searchByUser])

useEffect( () => {
    if ( !searchByUser ) {
        if ( searchByRef) {
            let fill = Payments.filter( payment => payment.payment_reference === searchByRef );
        setFiltered(fill )
        } else {
            setFiltered( Payments);
        }
   }
},[searchByRef])


    const Open = (data) => {
        setPageToShow( 'paymentdetails' );
        setPaymentDetails(data)
        Utils.showAdminPopUp();
    }



    const tableData = (payment) => {
        return (
             <div key={payment.t_id} className="table-data" onClick={()=>Open(payment)}>
                     <ul>
                     <li>{payment.id}</li>
                        <li>{payment.user}</li>
                        
                        
                        <li>{Utils.formatCurrency(parseInt(payment.amount))}</li>
                        <li style={setStatusBackground(payment)} className="status">{payment.status}</li>
                    </ul>
            </div>
        )
    }
    return (
        <section className="admin-payment-view">
            
            <div className="header">
            <h3>Payments</h3>
                <div>
                <input onChange={(e)=>setSearchByUser(e.target.value)}  type="text" placeholder="search by user"/>
                <input onChange={(e)=>setSearchByRef(e.target.value)}  type="text" placeholder="search by Ref"/>
                </div>
        
            </div>
            <div className="table">
                <div className="table-label">
                    <ul>
                        
                        <li>Payment ID</li>
                        <li>User</li>
                        <li>Amount</li>
                        <li>Status</li>
                    </ul>
                </div>
                { ( filtered ) ?
                    filtered.map( payment => (
                        tableData(payment)
                    ))
                 : null }
            </div>
        </section>
    )
}

export default Payments
