import React from 'react'
import "./Coupon.css"
import Buy from './Buy Coupon/Buy'
import Available from './Coupons/Available'
const Coupon = ({Products,User,errorMessage}) => {
    return (
        <section className="coupons">
            <h2>Coupons</h2>
            <div className="wrapper">
                <Buy Products={ Products } User={ User } errorMessage={ errorMessage }/>
                <Available User={ User }/>
            </div>
        </section>
    )
}

export default Coupon
