import React,{useEffect, useState} from 'react'
import './TotalTransactions.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions'
const TotalTransactions = ( { Transactions } ) => {

    let [price, setPrice] = useState(0)
 
   

    const getPrices = () => {

        price =0
        Transactions.map( t => {
            
            if ( t.status === 'successful' ) {
                price += parseFloat(t.value);
            setPrice(price)
            }
        })
    }

    useEffect( () => {
       
        if ( Transactions && Transactions.length !==0 ) {
            getPrices(); 
           }
        

        

    },[Transactions])

    return (
    <div className="total-transactions">
            <div className="value">
                
                <p><h5>NGN</h5>{ Utils.currency(parseFloat(price))}</p>
        </div>
        <p>Total Successful Transactions</p>
    </div>
    )
}

export default TotalTransactions
