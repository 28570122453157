import React from 'react'
import './TDetails.css'
import './mobile.css'
import Utils from '../../../Utils/Functions'
import store from '../../../../store';
import {deleteTransaction,reverse} from '../../../../actions/Action'
const TDetails = ( { Tdetails } ) => {
    
    const formatDate = ( date ) => {
        let hours = new Date( parseInt( date ) ).getHours() % 12;//To Get Local hours
        const minutes = new Date( parseInt( date ) ).getMinutes();
        let q = 'PM'

        const months = [ 'January', 'February', 'March', 'April', 'May',
            'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        
        const days = ['Sunday' , 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let Nday = new Date( parseInt( date ) ).getDate();
        let day = days[ new Date( parseInt( date ) ).getDay() ];
        let month = months[ new Date( parseInt( date ) ).getMonth() ];
        let year = new Date( parseInt( date ) ).getFullYear();

        if ( new Date( ( parseInt( date ) ) ).getHours()  < 12 ) {
             q = 'AM'
        }
        if ( Nday < 10 ) {
            Nday = '0' + Nday
        }
        if ( hours === 0 ) {
            hours = '12';
        }
        const d = {
            time: `${ new Date(parseInt( date ) ).toLocaleString()}`,
               
            date: ` ${ day }  ${ Nday } ${ month }, ${ year }`
         
            
        }

        return d
    };

    
    const deleteT = ( ) => {
        store.dispatch(deleteTransaction(Tdetails.transaction_id))
        Utils.closeAdminPopUp()
    }

    const reverseT = () => {
        let data = {
            userId: Tdetails._id,
            transactionId:Tdetails.transaction_id
        }
        store.dispatch(reverse(data))
        Utils.closeAdminPopUp()
    }
    return (
        <div className="admin-tdetails slowly-show-popups">
            <div className="right">
                {/* {console.log(Tdetails)} */}
                <p>Time: { formatDate(Tdetails.date).time}</p>
                <p>Date: { formatDate( Tdetails.date ).date }</p>
                { ( Tdetails.product === 'Airtime' || Tdetails.product === 'Data' ) ?
                    <p>Phone No: { Tdetails.transaction_phone }</p>
                    : ( Tdetails.product === 'Electricity' ) ?
                    <p>Meter No: {Tdetails.meter_umber ? Tdetails.meter_number: Tdetails.customerData.Meter_Number?Tdetails.customerData.Meter_Number : null}</p>
                        : ( Tdetails.product === 'CableTV' ) ?
                        <p>Smart Card Number No: {Tdetails.smart_card_number}</p>
                        : null
                }
                <p>Balance Before: { Utils.formatCurrency(parseFloat(Tdetails.balance_before))}</p>
                <p>Balance After: { Utils.formatCurrency(parseFloat(Tdetails.balance_after))}</p>
                        { Tdetails.plan ? <p>Plan: {Tdetails.plan }</p>: null}
                        { Tdetails.customerName ? <p>Customer: {Tdetails.customerName }</p>: null}
                        { Tdetails.account_id ? <p>Account ID: {Tdetails.account_id }</p>: null}
                { Tdetails.response_msg.msg ? <p>Status: { Tdetails.response_msg['msg'] }</p> : null }
                { Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.msg ? <p>R-STATUS: {Tdetails.response_msg.data.msg.response_description || Tdetails.response_msg.data.msg['data']['msg'] }</p>: null}
                        { Tdetails.amountCharged ? <p>Amount-Charged: {Utils.formatCurrency(parseFloat(Tdetails.amountCharged)) }</p>: null}
                        { Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.token ? <p>Token: {Tdetails.response_msg.data.token }</p>: Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.purchased_code? <p>Token: {Tdetails.response_msg.data.purchased_code}</p> : null}
                        { Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.exchangeReference ? <p>E-REF: {Tdetails.response_msg.data.exchangeReference }</p>: null}
                        { Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.units ? <p>Units: {Tdetails.response_msg.data.units }</p>: Tdetails.product !== 'Coupon' && Tdetails.response_msg.data.Units?<p>Units: {Tdetails.response_msg.data.Units }</p>:Tdetails.product !== 'Coupon' &&  Tdetails.response_msg.data.PurchasedUnits?<p>Units: {Tdetails.response_msg.data.PurchasedUnits }</p>: null}
            </div>
            
            <div className="left">
                <p onClick={()=>Utils.closeAdminPopUp()}>Close</p>
                
                {Tdetails.status == 'failed'? <p onClick={()=>reverseT()}>Reverse</p>: null}
                
            </div>
        </div>
    )
}

export default TDetails
