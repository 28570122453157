import React from 'react'
import './Walletbalance.css';
import Utils from '../../../../../Utils/Functions'
const Walletbalance = ({Balance}) => {
    
    // console.log(balance)
    return (
        <div className="admin-wallet-balance">
             <div className="value">
                <p><h5>NGN</h5> {Balance !==null?Utils.currency(parseInt(Balance)): null}</p>
            </div>
            <p>ATG Wallet Balance</p>
        </div>
    )
}

export default Walletbalance
