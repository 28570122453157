import React, { useEffect } from 'react'
import { useHistory } from 'react-router';

import './Overview.css'
import TotalNumber from './TotalNumberOfTransactions/TotalNumberOfTransactions';
import TotalTransactions from './TotalTransactions/TotalTransactions';
import WalletBalance from './Wallet/Wallet';
import Last5Transactions from './Last5Transactions/Last5Transactions'
const Overview = ({ Transactions, User }) => {
    const history = useHistory();


    useEffect(() => {
        if (User && !User.has_created_secret_pin) {
            history.push('/auth/createpin')
        }
        return () => ''
    }, [User])
    return (
        <section className="overview">
            <h2>Overview</h2>
            <div className="grid-wrapper">
                <TotalNumber Transactions={Transactions} />
                <TotalTransactions Transactions={Transactions} />
                <WalletBalance User={User} />

            </div>
            <Last5Transactions Transactions={Transactions} />
        </section>
    )
}

export default Overview
