import React, { useEffect } from 'react';
import './PopUps.css';
import TDetails from './TransactionsDetails/TransactionsDetails';
import Loading from './Loading/Loading';
import Payment from './Payments/Payments';

import { useHistory } from 'react-router-dom';
import Qs from 'query-string';
import { userLoad, verifyPayment } from '../../actions/Action';
import store from '../../store';
const PopUps = ({ pageToShow, Tdetails, errorMessage, setPageToShow }) => {
  const history = useHistory();
  useEffect(() => {
    if (window.location.search) {
      const { status, tx_ref, transaction_id } = Qs.parse(
        window.location.search
      );
      if (status && tx_ref && transaction_id) {
        store.dispatch(userLoad());
        const data = {
          status,
          tx_ref,
          transaction_id,
        };
        store.dispatch(verifyPayment(data));
      } else if (status && tx_ref) {
        // history.push('/user/dashboard');
      }

      //https://creditsync.com.ng/payment/verification?status=cancelled&tx_ref=2d17925cd4dd37df59db
    }
    return;
  }, [window.location.search]);
  useEffect(() => {
    if (errorMessage && errorMessage.type === 'verification_done') {
      // history.push('/user/dashboard');
    }
  }, [errorMessage]);

  return (
    <section className='popups'>
      {pageToShow === 'TDetails' ? (
        <TDetails Tdetails={Tdetails} setPageToShow={setPageToShow} />
      ) : pageToShow === 'Payment' ? (
        <Payment errorMessage={errorMessage} setPageToShow={setPageToShow} />
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default PopUps;
